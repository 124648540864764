<template>
  <div class="flex flex-col h-screen">
    <header class="bg-zinc-800 shadow-sm">
      <div class="flex-row flex justify-between">
        <div
          class="transition group ease-in-out duration-300 delay-150 hover:text-zinc-100 w-72 text-zinc-400 cursor-pointer border-r px-4 py-3 border-zinc-700 flex flex-row justify-between items-center shrink-0"
          @click.prevent="onSwitch"
        >
          <div>BTC-USD-PERP</div>

          <div class="flex flex-row items-center">
            <div
              class="text-xs tracking-tight text-zinc-500 group-hover:text-zinc-400 mb-0.5 transition ease-in-out duration-300 delay-150"
            >
              (cmd + s)
            </div>
            <SelectorIcon class="h-5 w-5 ml-1" />
          </div>

          <Hotkeys :shortcuts="['S']" @triggered="onSwitch" />
        </div>

        <div
          class="flex-grow flex items-center flex-row justify-between overflow-x-auto"
        >
          <div class="flex-grow flex flex-row">
            <div class="text-emerald-500 mx-4 flex items-center shrink-0">
              $<span class="font-mono">41,436</span>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">Index Price</div>
              <div class="text-zinc-300 text-xs">
                $<span class="font-mono">41,438</span>
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">Oracle Price</div>
              <div class="text-zinc-300 text-xs">
                $<span class="font-mono">41,438</span>
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">24h Change</div>
              <div class="text-red-500 text-xs">
                $<span class="font-mono">653.95 (1.54%)</span>
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">Open Interest</div>
              <div class="text-zinc-300 text-xs">
                <span class="font-mono">5,880.84</span>
                <span
                  class="bg-zinc-700 text-zinc-400 px-1 uppercase rounded ml-1"
                  >btc</span
                >
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">1h Funding</div>
              <div class="text-emerald-500 text-xs">
                <span class="font-mono">0.001864%</span>
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">24h Volume</div>
              <div class="text-zinc-300 text-xs">
                $<span class="font-mono">782,738,844</span>
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">Next Funding</div>
              <div class="text-zinc-300 text-xs">
                <span class="font-mono">38:44</span>
              </div>
            </div>
            <div class="">&nbsp;</div>
          </div>
        </div>

        <div
          class="shrink-0 my-2 text-zinc-400 px-3 mr-1 border-l border-zinc-700 h-8 flex items-center transition ease-in-out duration-300 delay-150 hover:text-zinc-200 cursor-pointer"
        >
          <PlusIcon class="h-4 w-4" />
        </div>
      </div>
    </header>

    <main class="flex-grow">
      <div
        class="w-full h-full mx-auto flex bg-zinc-800 border-b border-t border-zinc-700"
      >
        <!-- Left sidebar & main wrapper -->
        <div class="flex-1 min-w-0 flex">
          <div class="border-zinc-700 flex-shrink-0 w-full md:w-72 md:border-r">
            <div class="h-full flex flex-col justify-between">
              <div
                class="flex flex-col pt-12 pb-10 px-4 border-b border-zinc-700 items-center justify-center space-y-6"
              >
                <span class="text-center text-xs px-6 text-zinc-400">
                  Connect your Ethereum wallet to start trading.
                </span>

                <div class="flex flex-col text-center">
                  <FullButton
                    text="Connect wallet"
                    text-color="text-indigo-200"
                    background-color="bg-indigo-700"
                    border-color="border-indigo-500"
                    gradient-color="from-indigo-600"
                    size="text-sm"
                    @clicked="connect()"
                  />
                  <span class="text-xs text-zinc-500 m-1"
                    >No account required</span
                  >
                </div>
              </div>

              <div class="flex-grow">
                <div
                  class="border-b border-zinc-700 grid grid-cols-3 text-center"
                >
                  <div
                    class="py-3 text-sm font-base text-zinc-300 bg-zinc-900 border-r border-zinc-700"
                  >
                    Market
                  </div>
                  <div
                    class="py-3 text-sm text-zinc-500 bg-zinc-800 font-base transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
                  >
                    Limit
                  </div>
                  <div
                    class="py-3 text-sm text-zinc-500 bg-zinc-800 font-base transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
                  >
                    Stop
                  </div>
                </div>

                <div class="px-4 py-6 text-zinc-400 text-sm space-y-4">
                  <div class="grid grid-cols-2">
                    <div class="pr-1">
                      <BorderButton
                        size="text-sm"
                        py="py-2"
                        color="border-zinc-500"
                        text="Sell"
                      />
                    </div>
                    <div class="pl-1">
                      <BorderButton
                        size="text-sm"
                        py="py-2"
                        color="border-emerald-500"
                        text="Buy"
                        active
                      />
                    </div>
                  </div>

                  <div>
                    <div class="text-xs text-zinc-300">
                      Amount <span class="text-zinc-500">Set order size</span>
                    </div>
                    <div>
                      <div class="mt-1 relative rounded-md">
                        <input
                          type="text"
                          name="price"
                          id="price"
                          class="block w-full font-mono px-4 py-2.5 pr-12 sm:text-sm border-zinc-700 rounded bg-zinc-700"
                          placeholder="0.00"
                          aria-describedby="price-currency"
                        />
                        <div
                          class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                        >
                          <span
                            class="text-zinc-500 sm:text-sm"
                            id="price-currency"
                          >
                            BTC
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="flex flex-row justify-between items-center">
                      <div class="text-xs text-zinc-300">
                        Leverage
                        <span class="text-zinc-500"
                          >Up to <span class="font-mono">100x</span></span
                        >
                      </div>
                      <div
                        class="text-xs py-1 px-2 bg-zinc-700 rounded font-mono"
                      >
                        {{ leverage }}x
                      </div>
                    </div>
                    <div>
                      <input
                        type="range"
                        v-model="leverage"
                        class="appearance-none rounded w-full h-2 p-0 bg-zinc-700 bg-gradient-to-l from-indigo-700 focus:outline-none focus:ring-0 cursor-pointer"
                        min="1"
                        max="100"
                        step="1"
                      />
                    </div>
                    <div
                      class="grid grid-cols-5 text-xs text-zinc-400 font-mono"
                    >
                      <div>0x</div>
                      <div>25x</div>
                      <div class="text-center">50x</div>
                      <div class="text-right">75x</div>
                      <div class="text-right">100x</div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="px-4 py-6 text-zinc-500 text-sm">
                  <div class="bg-zinc-900 rounded">
                    <div class="p-2 text-xs space-y-2">
                      <div class="flex justify-between">
                        <div>Expected Price</div>
                        <div class="text-zinc-300">-</div>
                      </div>
                      <div class="flex justify-between">
                        <div>Price Impact</div>
                        <div class="text-zinc-300">-</div>
                      </div>
                      <div class="flex justify-between">
                        <div>Fee</div>
                        <div class="text-zinc-300">-</div>
                      </div>
                      <div class="flex justify-between">
                        <div>Total</div>
                        <div class="text-zinc-300">-</div>
                      </div>
                    </div>
                    <FullButton
                      size="text-sm"
                      text-color="text-zinc-400"
                      text="Place order"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="border-zinc-700 flex-shrink-0 w-80 border-r hidden lg:block"
          >
            <div class="h-full">
              <OrderBook />
            </div>
          </div>

          <div class="min-w-0 flex-1 hidden md:block">
            <div class="h-full">
              <div class="h-full flex flex-col justify-between">
                <div class="flex-grow bg-zinc-900">
                  <TVChartContainer />
                </div>

                <div
                  class="border-t border-zinc-700 h-80 flex flex-col justify-between"
                >
                  <div class="border-b border-zinc-700 text-center">
                    <div
                      class="flex flex-row justify-between overflow-x-auto px-4"
                    >
                      <div
                        class="flex-grow shrink-0 mx-2 py-3 text-xs font-base text-zinc-300 items-center bg-zinc-800 flex justify-center"
                      >
                        Position
                      </div>
                      <div
                        class="flex-grow shrink-0 mx-2 items-center flex justify-center px-1 py-3 text-xs text-zinc-500 bg-zinc-800 font-base transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
                      >
                        Open Orders
                      </div>
                      <div
                        class="flex-grow shrink-0 mx-2 items-center flex justify-center px-1 py-3 text-xs text-zinc-500 bg-zinc-800 font-base transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
                      >
                        Order History
                      </div>
                      <div
                        class="flex-grow shrink-0 mx-2 items-center flex justify-center px-1 py-3 text-xs text-zinc-500 bg-zinc-800 font-base transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
                      >
                        Trade History
                      </div>
                      <div
                        class="flex-grow shrink-0 mx-2 items-center flex justify-center px-1 py-3 text-xs text-zinc-500 bg-zinc-800 font-base transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
                      >
                        Funding History
                      </div>
                    </div>
                  </div>

                  <div
                    class="flex-grow bg-zinc-900 flex justify-center items-center"
                  >
                    <span class="text-zinc-600 text-sm">No position found</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-shrink-0 border-l border-zinc-700 hidden xl:block">
            <PublicTrades />
          </div>
        </div>
      </div>
    </main>

    <footer class="bg-zinc-800">
      <div class="flex flex-row items-center justify-between h-8 pb-0.5">
        <div
          class="text-xs text-zinc-400 flex items-center w-72 md:border-r border-zinc-700 px-4 py-1"
        >
          <div class="w-1.5 h-1.5 rounded-full bg-green-500 mr-2"></div>
          <div
            class="text-xs text-zinc-400 flex items-center w-72 transition ease-in-out duration-300 delay-150 hover:text-zinc-200 cursor-pointer"
          >
            Operational <ExternalLinkIcon class="h-4 w-4 ml-1" />
          </div>
        </div>

        <div class="flex-grow md:border-r border-zinc-700">&nbsp;</div>

        <div
          class="text-xs text-zinc-400 flex justify-end md:justify-start items-center w-72 px-4 transition ease-in-out duration-300 delay-150 hover:text-zinc-200 cursor-pointer"
        >
          Join us on discord <ExternalLinkIcon class="h-4 w-4 ml-1" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import FullButton from "@/components/button/FullButton.vue";
import BorderButton from "@/components/button/BorderButton.vue";
import TVChartContainer from "@/components/tradingview/TVChartContainer";
import OrderBook from "@/components/marketdata/OrderBook";
import {
  PlusIcon,
  ExternalLinkIcon,
  SelectorIcon,
} from "@heroicons/vue/outline";
import { ethers } from "ethers";
import Hotkeys from "vue-hotkeys-rt";
import PublicTrades from "@/components/marketdata/PublicTrades";

export default defineComponent({
  name: "FuturesView",
  components: {
    PublicTrades,
    OrderBook,
    TVChartContainer,
    BorderButton,
    FullButton,
    ExternalLinkIcon,
    PlusIcon,
    SelectorIcon,
    Hotkeys,
  },

  data() {
    return {
      leverage: 1,
      time: "",
      timer: null,
    };
  },

  methods: {
    async connect() {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);

      const signer = await provider.getSigner();

      //const block = await provider.getBlockNumber()
      //
      /*const resolver = await provider.getResolver("ropsten.eth")
      const address = await resolver.getAddress()
      console.log(address)
      const balance = await provider.getBalance(address)*/

      alert("balance: " + (await signer.getBalance()) / 1e18 + " eth");
    },

    onSwitch() {
      this.$router.push({
        name: "trade.listings",
      });
    },
  },
});
</script>
