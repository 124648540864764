<template>
  <div class="h-full">
    <div v-show="loaded" class="TVChartContainer" :id="containerId" />
    <div
      v-show="!loaded"
      class="flex flex-col justify-center items-center h-full"
    >
      <div class="border-b border-zinc-700 bg-zinc-800 h-tv-chart w-full"></div>
      <div class="flex-grow flex justify-center items-center">
        <HorizontalLoader />
      </div>
    </div>
  </div>
</template>

<script>
import { widget } from "../../../public/charting_library";
import HorizontalLoader from "@/components/loader/HorizontalLoader";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
export default {
  name: "TVChartContainer",
  components: { HorizontalLoader },
  data() {
    return {
      loaded: false,
    };
  },

  props: {
    symbol: {
      default: "AAPL",
      type: String,
    },
    interval: {
      default: "D",
      type: String,
    },
    containerId: {
      default: "tv_chart_container",
      type: String,
    },
    datafeedUrl: {
      default: "https://demo_feed.tradingview.com",
      type: String,
    },
    libraryPath: {
      default: "/charting_library/",
      type: String,
    },
    chartsStorageUrl: {
      default: "https://saveload.tradingview.com",
      type: String,
    },
    chartsStorageApiVersion: {
      default: "1.1",
      type: String,
    },
    clientId: {
      default: "tradingview.com",
      type: String,
    },
    userId: {
      default: "public_user_id",
      type: String,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    autosize: {
      default: true,
      type: Boolean,
    },
    studiesOverrides: {
      type: Object,
    },
  },

  tvWidget: null,

  mounted() {
    const widgetOptions = {
      symbol: this.symbol,
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(this.datafeedUrl),
      interval: this.interval,
      container: this.containerId,
      library_path: this.libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_symbol_search",
        "left_toolbar",
        "timeframes_toolbar",
        "go_to_date",
        "timezone_menu",
        "header_saveload",
        "header_compare",
        "popup_hints",
      ],
      enabled_features: [],
      charts_storage_url: this.chartsStorageUrl,
      charts_storage_api_version: this.chartsStorageApiVersion,
      client_id: this.clientId,
      user_id: this.userId,
      fullscreen: this.fullscreen,
      autosize: this.autosize,
      studies_overrides: this.studiesOverrides,
      theme: "dark",
      custom_css_url: "../chart.css",
      overrides: {
        "paneProperties.backgroundType": "solid",
        "paneProperties.background": "#18181b",
        "mainSeriesProperties.candleStyle.upColor": "#12b981",
        "mainSeriesProperties.candleStyle.borderUpColor": "#12b981",
        "mainSeriesProperties.candleStyle.downColor": "#e34243",
        "mainSeriesProperties.candleStyle.borderDownColor": "#e34243",
      },
    };
    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      this.loaded = true;
    });
  },

  unmounted() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.TVChartContainer {
  height: 100%;
}
</style>
