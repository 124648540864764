<template>
  <div
    class="h-screen w-screen flex flex-row justify-center items-center overflow-y-hidden"
  >
    <div class="relative block w-full h-full">
      <div class="sky w-full h-full">
        <div class="stars"></div>
        <div class="stars1"></div>
        <div class="stars2"></div>
        <div class="shooting-stars"></div>

        <div class="flex h-full flex-col justify-between mx-20">
          <div class="h-10 text-zinc-400 mt-8">
            <div
              @click="$router.push({ name: 'trade' })"
              class="inline-flex transition duration-500 delay-150 items-center text-zinc-400 bg-zinc-900 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-zinc-100 cursor-pointer"
            >
              <span
                class="px-3 py-0.5 text-zinc-200 text-xs font-semibold leading-5 uppercase tracking-wide"
                >latest news</span
              >
              <span class="ml-4 text-sm"
                >Dopex, AAVE and Convex trading pairs now listed</span
              >

              <ChevronRightIcon class="ml-2 w-5 h-5 text-zinc-500" />
            </div>
          </div>

          <div
            id="content"
            class="opacity-0 mt-36 flex-grow flex items-center grid lg:grid-cols-12 text-center lg:text-left mx-auto lg:mx-0"
          >
            <div class="col-span-9">
              <h1
                class="mt-4 text-3xl font-bold text-zinc-300 sm:mt-5 sm:leading-none lg:text-8xl"
              >
                <span class="md:block">Trade to</span>
                <span
                  class="text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-yellow-500"
                >
                  the Moon </span
                >🌚
              </h1>
              <p
                class="mt-3 text-base font-light text-zinc-400 sm:mt-5 sm:text-xl lg:text-xl"
              >
                Combining the trust of
                <span class="text-zinc-200 font-semibold">DeFi</span> with the
                speed of <span class="text-zinc-200 font-semibold">CeFi</span>.
                <br /><span class="text-zinc-200 font-semibold"
                  >No signup required</span
                >
                to trade.
              </p>

              <div
                class="flex flex-row justify-center lg:justify-start items-center"
              >
                <div class="my-8 lg:w-64">
                  <BorderButton
                    @clicked="$router.push({ name: 'trade' })"
                    color="border-yellow-700 text-yellow-600"
                    text="Launch Exchange"
                  />
                </div>

                <div
                  @click="$router.push({ name: 'resources' })"
                  class="ml-8 cursor-pointer hover:text-zinc-200 transition duration-500 delay-150 text-zinc-400 flex flex-row items-center"
                >
                  Learn More
                  <ChevronRightIcon class="ml-2 w-5 h-5" />
                </div>
              </div>
            </div>
          </div>

          <div
            class="h-10 text-zinc-400 flex flex-row justify-center lg:justify-start mb-2"
          >
            <div class="text-xs text-zinc-400 flex items-center pr-6 py-1">
              <div class="w-1.5 h-1.5 rounded-full bg-green-500 mr-2"></div>
              <div
                class="text-xs text-zinc-400 flex items-center transition ease-in-out duration-300 delay-150 hover:text-zinc-200 cursor-pointer"
              >
                Operational <ExternalLinkIcon class="h-4 w-4 ml-1 mb-0.5" />
              </div>
            </div>

            <div
              class="text-xs text-zinc-400 flex items-center justify-center lg:justify-start transition ease-in-out duration-300 delay-150 hover:text-zinc-200 cursor-pointer"
            >
              Join us on discord
              <ExternalLinkIcon class="h-4 w-4 ml-1 mb-0.5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExternalLinkIcon, ChevronRightIcon } from "@heroicons/vue/outline";
import BorderButton from "@/components/button/BorderButton";
import gsap from "gsap";

export default {
  name: "HomeView",
  components: { BorderButton, ExternalLinkIcon, ChevronRightIcon },

  mounted() {
    gsap.to(`#content`, {
      opacity: 1.0,
      marginTop: 0.0,
      duration: 0.7,
    });
  },
};
</script>

<style scoped>
.sky {
  background: linear-gradient(to bottom, #020107 0%, #170f2a 100%);
}

.stars {
  z-index: 10;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 2389px 533px #fff, 543px 1029px #fff, 816px 562px #fff,
    1109px 172px #fff, 269px 672px #fff, 189px 2071px #fff, 947px 1969px #fff,
    966px 1480px #fff, 1774px 1537px #fff, 2296px 837px #fff, 1884px 52px #fff,
    2342px 651px #fff, 2496px 874px #fff, 971px 2071px #fff, 1902px 2123px #fff,
    1048px 1105px #fff, 529px 1631px #fff, 502px 553px #fff, 2559px 1311px #fff,
    634px 2181px #fff, 1277px 2498px #fff, 732px 1227px #fff, 1628px 141px #fff,
    283px 2420px #fff, 1563px 768px #fff, 2123px 1459px #fff, 2001px 593px #fff,
    2391px 1194px #fff, 160px 562px #fff, 943px 504px #fff, 706px 992px #fff,
    1599px 615px #fff, 1364px 2407px #fff, 1633px 354px #fff, 1522px 2265px #fff,
    971px 652px #fff, 2554px 332px #fff, 2228px 177px #fff, 2426px 590px #fff,
    1178px 1609px #fff, 2311px 2067px #fff, 218px 684px #fff, 154px 1706px #fff,
    1168px 952px #fff, 2435px 2315px #fff, 2032px 1429px #fff, 195px 1857px #fff,
    698px 1705px #fff, 2036px 2058px #fff, 1806px 2492px #fff, 1593px 520px #fff,
    1054px 689px #fff, 488px 350px #fff, 943px 1775px #fff, 1624px 2041px #fff,
    2294px 473px #fff, 2370px 747px #fff, 1624px 938px #fff, 1521px 1069px #fff,
    1143px 2221px #fff, 2219px 1146px #fff, 2080px 380px #fff,
    1506px 1504px #fff, 440px 1818px #fff, 1131px 2483px #fff,
    1963px 1974px #fff, 45px 1834px #fff, 2426px 24px #fff, 2218px 1003px #fff,
    1840px 813px #fff, 901px 847px #fff, 1771px 2374px #fff, 2316px 2508px #fff,
    1435px 262px #fff, 590px 2526px #fff, 2096px 659px #fff, 2289px 2277px #fff,
    1913px 1739px #fff, 2143px 44px #fff, 1586px 45px #fff, 511px 1815px #fff,
    1348px 2531px #fff, 2023px 376px #fff, 565px 1828px #fff, 935px 1060px #fff,
    2032px 1041px #fff, 1568px 2082px #fff, 1669px 635px #fff,
    2029px 2546px #fff, 906px 50px #fff, 1831px 2113px #fff, 231px 1537px #fff,
    347px 1483px #fff, 1118px 2128px #fff, 2256px 1841px #fff,
    2334px 1598px #fff, 1140px 1206px #fff, 905px 1716px #fff,
    2541px 1923px #fff, 414px 2295px #fff, 916px 778px #fff, 942px 149px #fff,
    1510px 1360px #fff, 1941px 857px #fff, 1775px 1126px #fff,
    1517px 2113px #fff, 2053px 1567px #fff, 1510px 943px #fff, 827px 2088px #fff,
    2139px 1367px #fff, 2373px 2113px #fff, 1540px 1764px #fff,
    2183px 2399px #fff, 2499px 728px #fff, 2243px 1923px #fff,
    1830px 1040px #fff, 1150px 2130px #fff, 54px 1204px #fff, 95px 91px #fff,
    328px 2347px #fff, 2000px 1280px #fff, 2543px 2472px #fff,
    1611px 1076px #fff, 174px 1208px #fff, 1427px 326px #fff, 854px 149px #fff,
    1155px 455px #fff, 1781px 1995px #fff, 415px 1650px #fff, 191px 432px #fff,
    816px 1877px #fff, 1158px 1303px #fff, 1150px 1083px #fff, 2506px 810px #fff,
    533px 1480px #fff, 1816px 910px #fff, 1880px 818px #fff, 378px 2136px #fff,
    1172px 1725px #fff, 1632px 1635px #fff, 877px 2518px #fff,
    1235px 1643px #fff, 1281px 1280px #fff, 2103px 1410px #fff,
    1295px 478px #fff, 1579px 1323px #fff, 920px 1605px #fff, 2541px 2102px #fff,
    1056px 23px #fff, 2px 1057px #fff, 1596px 2147px #fff, 2355px 1651px #fff,
    714px 862px #fff, 2262px 1829px #fff, 288px 2364px #fff, 2480px 1144px #fff,
    1749px 2283px #fff, 2474px 1711px #fff, 1745px 992px #fff, 2547px 94px #fff,
    540px 543px #fff, 1525px 1353px #fff, 2371px 220px #fff, 1318px 950px #fff,
    2404px 1215px #fff, 2001px 1882px #fff, 1099px 1527px #fff, 21px 2014px #fff,
    1306px 1019px #fff, 2373px 520px #fff, 185px 155px #fff, 61px 832px #fff,
    1223px 1994px #fff, 426px 768px #fff, 1068px 1240px #fff, 158px 1311px #fff,
    430px 63px #fff, 1030px 1034px #fff, 1925px 1855px #fff, 366px 1650px #fff,
    991px 2510px #fff, 218px 652px #fff, 1842px 1269px #fff, 2309px 1808px #fff,
    853px 1917px #fff, 815px 1338px #fff, 506px 1730px #fff, 460px 240px #fff,
    1252px 1084px #fff, 596px 2413px #fff, 2431px 1264px #fff,
    1892px 1387px #fff, 2010px 1878px #fff, 1398px 1789px #fff,
    521px 2436px #fff, 313px 2180px #fff, 208px 1108px #fff, 955px 2317px #fff,
    952px 2420px #fff, 705px 155px #fff, 1909px 967px #fff, 129px 1235px #fff,
    1246px 1368px #fff, 2303px 885px #fff, 158px 2355px #fff, 1515px 1043px #fff,
    1524px 575px #fff, 467px 1044px #fff, 1155px 2179px #fff, 172px 170px #fff,
    821px 1143px #fff, 1937px 36px #fff, 2447px 2180px #fff, 162px 211px #fff,
    2459px 1848px #fff, 36px 68px #fff, 2227px 1279px #fff, 1764px 2251px #fff,
    2103px 741px #fff, 1502px 1397px #fff, 965px 2490px #fff, 541px 236px #fff,
    1818px 2113px #fff, 2233px 751px #fff, 778px 2139px #fff, 37px 808px #fff,
    1785px 2322px #fff, 894px 668px #fff, 1962px 1492px #fff, 209px 322px #fff,
    1435px 2048px #fff, 898px 2340px #fff, 2252px 1866px #fff,
    1843px 2539px #fff, 1247px 2249px #fff, 358px 2463px #fff, 797px 2066px #fff,
    2094px 472px #fff, 493px 62px #fff, 1524px 1452px #fff, 845px 2411px #fff,
    112px 550px #fff, 1391px 2236px #fff, 1610px 1903px #fff, 270px 1009px #fff,
    244px 1660px #fff, 1638px 1744px #fff, 1974px 2249px #fff, 709px 1840px #fff,
    121px 2205px #fff, 1057px 1854px #fff, 1977px 1186px #fff, 963px 2222px #fff,
    2378px 1560px #fff, 505px 813px #fff, 2254px 597px #fff, 1569px 1476px #fff,
    1002px 2537px #fff, 2199px 768px #fff, 1019px 2022px #fff,
    1600px 1733px #fff, 1730px 2531px #fff, 1845px 2382px #fff,
    2010px 926px #fff, 2533px 1939px #fff, 1622px 259px #fff, 553px 1985px #fff,
    1130px 853px #fff, 501px 388px #fff, 966px 215px #fff, 129px 1947px #fff,
    1887px 382px #fff, 2406px 335px #fff, 1750px 2447px #fff, 1268px 204px #fff,
    1885px 1369px #fff, 2079px 2112px #fff, 1910px 1445px #fff,
    1775px 424px #fff, 1809px 1342px #fff, 1523px 961px #fff, 2050px 994px #fff,
    1440px 1362px #fff, 1353px 272px #fff, 2131px 956px #fff, 272px 1310px #fff,
    219px 2268px #fff, 804px 2104px #fff, 828px 2105px #fff, 1938px 1898px #fff,
    1406px 1584px #fff, 2404px 828px #fff, 1121px 1531px #fff,
    1532px 2532px #fff, 1258px 1236px #fff, 1150px 712px #fff,
    2057px 1082px #fff, 592px 1659px #fff, 1638px 1006px #fff, 702px 536px #fff,
    1525px 1290px #fff, 2469px 1181px #fff, 2425px 465px #fff, 2306px 977px #fff,
    1259px 1483px #fff, 1853px 1344px #fff, 541px 2529px #fff, 727px 2184px #fff,
    2162px 1014px #fff, 114px 197px #fff, 854px 2292px #fff, 258px 2498px #fff,
    1615px 609px #fff, 380px 1148px #fff, 2020px 1021px #fff, 828px 1926px #fff,
    1690px 302px #fff, 1017px 162px #fff, 1216px 1911px #fff, 2330px 1320px #fff,
    827px 731px #fff, 1420px 1204px #fff, 176px 1500px #fff, 2498px 638px #fff,
    272px 1267px #fff, 880px 1961px #fff, 1622px 2003px #fff, 987px 506px #fff,
    1752px 1057px #fff, 1503px 1441px #fff, 2149px 2356px #fff,
    1054px 586px #fff, 2543px 1525px #fff, 609px 60px #fff, 1020px 949px #fff,
    1422px 2319px #fff, 1094px 252px #fff, 1465px 2059px #fff, 2019px 362px #fff,
    111px 197px #fff, 1565px 2022px #fff, 307px 393px #fff, 948px 2452px #fff,
    760px 1336px #fff, 545px 2px #fff, 1731px 2080px #fff, 91px 136px #fff,
    1812px 319px #fff, 904px 1375px #fff, 912px 819px #fff, 293px 2384px #fff,
    1207px 1413px #fff, 279px 2241px #fff, 1203px 710px #fff, 1380px 786px #fff,
    1917px 745px #fff, 231px 214px #fff, 1019px 656px #fff, 827px 1297px #fff,
    654px 5px #fff, 594px 2399px #fff, 511px 575px #fff, 963px 361px #fff,
    902px 178px #fff, 2455px 1509px #fff, 1124px 604px #fff, 2166px 356px #fff,
    1281px 222px #fff, 1118px 78px #fff, 2139px 1813px #fff, 752px 2468px #fff,
    1405px 717px #fff, 673px 419px #fff, 1701px 900px #fff, 517px 797px #fff,
    1726px 845px #fff, 1014px 2214px #fff, 326px 907px #fff, 1989px 179px #fff,
    2339px 1964px #fff, 932px 996px #fff, 1586px 482px #fff, 1361px 653px #fff,
    1895px 1950px #fff, 1367px 549px #fff, 1789px 897px #fff, 750px 1726px #fff,
    854px 1591px #fff, 1051px 268px #fff, 367px 398px #fff, 215px 465px #fff,
    1281px 1333px #fff, 267px 487px #fff, 1412px 748px #fff, 1809px 748px #fff,
    609px 1661px #fff, 1262px 97px #fff, 1832px 522px #fff, 2064px 1595px #fff,
    798px 1886px #fff, 408px 64px #fff, 1180px 849px #fff, 1483px 24px #fff,
    172px 1163px #fff, 1119px 335px #fff, 31px 910px #fff, 1748px 311px #fff,
    906px 174px #fff, 1252px 2517px #fff, 1705px 1401px #fff, 1458px 140px #fff,
    779px 1752px #fff, 186px 240px #fff, 2047px 2124px #fff, 1457px 1381px #fff,
    1761px 14px #fff, 1811px 1255px #fff, 646px 2131px #fff, 1181px 608px #fff,
    1717px 2181px #fff, 2140px 1165px #fff, 1505px 783px #fff,
    1792px 1853px #fff, 2089px 1510px #fff, 2253px 937px #fff,
    1810px 2466px #fff, 572px 2548px #fff, 2181px 811px #fff, 384px 894px #fff,
    1580px 1977px #fff, 1290px 167px #fff, 1121px 2343px #fff,
    1653px 2015px #fff, 1257px 741px #fff, 692px 903px #fff, 76px 438px #fff,
    81px 1157px #fff, 207px 2434px #fff, 2140px 1195px #fff, 2056px 1489px #fff,
    411px 1526px #fff, 964px 1104px #fff, 1542px 1593px #fff, 1466px 894px #fff,
    2246px 1243px #fff, 707px 2184px #fff, 245px 2531px #fff, 61px 2002px #fff,
    8px 514px #fff, 2266px 1651px #fff, 1185px 484px #fff, 673px 2406px #fff,
    1248px 993px #fff, 1183px 682px #fff, 673px 1173px #fff, 344px 264px #fff,
    1657px 1887px #fff, 278px 614px #fff, 163px 1519px #fff, 370px 590px #fff,
    2471px 1807px #fff, 1657px 1505px #fff, 1247px 1995px #fff,
    1091px 847px #fff, 2198px 1750px #fff, 1993px 2513px #fff,
    2427px 2079px #fff, 1731px 251px #fff, 1614px 61px #fff, 1874px 1375px #fff,
    102px 787px #fff, 1090px 1697px #fff, 899px 1596px #fff, 2467px 566px #fff,
    232px 1428px #fff, 2319px 448px #fff, 2469px 1828px #fff, 22px 355px #fff,
    111px 468px #fff, 1012px 1874px #fff, 128px 2122px #fff, 704px 829px #fff,
    2376px 618px #fff, 986px 1949px #fff, 1742px 1609px #fff, 1882px 2094px #fff,
    966px 1864px #fff, 72px 1433px #fff, 1947px 2135px #fff, 1858px 1987px #fff,
    2182px 1915px #fff, 2489px 1317px #fff, 656px 452px #fff, 644px 894px #fff,
    1457px 1025px #fff, 1752px 1235px #fff, 2422px 67px #fff, 489px 1156px #fff,
    1555px 1693px #fff, 1470px 626px #fff, 1002px 358px #fff, 407px 1400px #fff,
    1823px 1930px #fff, 1566px 419px #fff, 816px 184px #fff, 827px 1171px #fff,
    492px 2035px #fff, 930px 2234px #fff, 1109px 829px #fff, 217px 54px #fff,
    2200px 1378px #fff, 257px 1963px #fff, 1119px 259px #fff, 920px 2034px #fff,
    1062px 1684px #fff, 2373px 1879px #fff, 2411px 749px #fff, 2524px 388px #fff,
    1655px 1350px #fff, 619px 720px #fff, 320px 1898px #fff, 801px 2269px #fff,
    746px 1159px #fff, 314px 1525px #fff, 2443px 1946px #fff, 1847px 802px #fff,
    1100px 2088px #fff, 92px 2144px #fff, 474px 380px #fff, 1633px 1606px #fff,
    2086px 383px #fff, 596px 2204px #fff, 517px 1173px #fff, 592px 1506px #fff,
    1194px 194px #fff, 1583px 333px #fff, 2348px 792px #fff, 1088px 350px #fff,
    28px 986px #fff, 959px 1516px #fff, 1132px 2084px #fff, 1546px 1598px #fff,
    74px 1345px #fff, 1684px 1493px #fff, 1517px 2218px #fff, 1762px 1696px #fff,
    667px 1065px #fff, 251px 1616px #fff, 546px 1585px #fff, 1603px 2444px #fff,
    1971px 1875px #fff, 307px 1780px #fff, 2095px 1449px #fff,
    2385px 2021px #fff, 2175px 89px #fff, 1987px 594px #fff, 1808px 535px #fff,
    2494px 303px #fff, 1269px 1531px #fff, 219px 1609px #fff, 1531px 1505px #fff,
    884px 1820px #fff, 1403px 450px #fff, 90px 2357px #fff, 1750px 383px #fff,
    582px 1297px #fff, 331px 2331px #fff, 59px 1636px #fff, 2324px 1203px #fff,
    1672px 342px #fff, 1713px 1936px #fff, 1828px 873px #fff, 1961px 231px #fff,
    1048px 1745px #fff, 1965px 202px #fff, 1318px 1402px #fff, 177px 1299px #fff,
    395px 1567px #fff, 1100px 653px #fff, 1121px 2066px #fff, 1953px 1547px #fff,
    2023px 1226px #fff, 1363px 1875px #fff, 789px 2028px #fff,
    1044px 1888px #fff, 1880px 1615px #fff, 356px 2261px #fff,
    1457px 1547px #fff, 2267px 2528px #fff, 26px 1834px #fff, 289px 1582px #fff,
    1440px 2375px #fff, 567px 2558px #fff, 2448px 2261px #fff, 1785px 278px #fff,
    1531px 85px #fff, 2221px 330px #fff, 2287px 1874px #fff, 1267px 1401px #fff,
    1619px 2087px #fff, 1526px 2192px #fff, 108px 1847px #fff, 694px 1734px #fff,
    871px 181px #fff, 853px 2135px #fff, 1349px 2399px #fff, 1835px 163px #fff,
    313px 882px #fff, 2432px 1878px #fff, 2366px 2309px #fff, 2290px 1009px #fff,
    969px 569px #fff, 199px 1320px #fff, 2184px 884px #fff, 1936px 699px #fff,
    1843px 2269px #fff, 2457px 876px #fff, 1778px 1200px #fff, 921px 491px #fff,
    783px 2057px #fff, 122px 1739px #fff, 362px 912px #fff, 828px 353px #fff,
    1834px 810px #fff, 2048px 1697px #fff, 1780px 2202px #fff, 2070px 272px #fff,
    1839px 1446px #fff, 602px 2420px #fff, 784px 1270px #fff, 1005px 2390px #fff,
    182px 1645px #fff, 857px 2032px #fff, 1373px 282px #fff, 1280px 1220px #fff,
    1955px 1483px #fff, 4px 2211px #fff, 4px 1701px #fff, 1338px 2115px #fff,
    348px 4px #fff, 1760px 412px #fff, 332px 1646px #fff, 756px 1386px #fff,
    1790px 1127px #fff, 1841px 1161px #fff, 404px 1085px #fff, 887px 842px #fff,
    649px 1080px #fff, 842px 1168px #fff, 1057px 1778px #fff, 11px 491px #fff,
    1270px 226px #fff, 850px 2052px #fff, 798px 1960px #fff, 1751px 2334px #fff,
    600px 2518px #fff, 2193px 2149px #fff, 2177px 211px #fff, 327px 577px #fff,
    1160px 431px #fff, 2331px 2315px #fff, 2175px 144px #fff, 1299px 1661px #fff,
    1947px 932px #fff, 898px 1112px #fff, 490px 2367px #fff, 1493px 845px #fff,
    2014px 237px #fff, 65px 1039px #fff, 1588px 1548px #fff, 733px 80px #fff,
    2091px 645px #fff, 1295px 1358px #fff, 1875px 1183px #fff, 710px 1941px #fff,
    852px 1211px #fff, 2179px 466px #fff, 2194px 786px #fff, 769px 2201px #fff,
    110px 1442px #fff, 843px 166px #fff, 1891px 1271px #fff, 622px 159px #fff,
    1640px 2483px #fff, 701px 2335px #fff, 347px 1185px #fff, 859px 2403px #fff,
    908px 633px #fff, 989px 2504px #fff, 389px 31px #fff, 601px 276px #fff,
    1275px 2268px #fff, 1765px 46px #fff, 919px 1727px #fff, 1485px 1972px #fff,
    956px 357px #fff, 543px 1276px #fff, 2356px 2088px #fff, 2249px 1413px #fff,
    1745px 2224px #fff, 27px 940px #fff, 442px 218px #fff, 57px 2146px #fff,
    819px 280px #fff, 1103px 2533px #fff, 1043px 2320px #fff, 2037px 534px #fff,
    1943px 761px #fff, 384px 115px #fff, 1542px 2011px #fff, 1452px 2212px #fff,
    1705px 2281px #fff, 1279px 1254px #fff, 947px 1491px #fff,
    2372px 1822px #fff, 2438px 1472px #fff, 155px 160px #fff, 574px 2328px #fff,
    2071px 1289px #fff, 2239px 319px #fff, 1242px 1914px #fff, 422px 119px #fff,
    1189px 2356px #fff, 2349px 790px #fff, 1845px 401px #fff, 612px 2430px #fff,
    1665px 2329px #fff, 451px 798px #fff, 1262px 2389px #fff, 1779px 1301px #fff,
    781px 2111px #fff, 2394px 357px #fff, 723px 2266px #fff, 1494px 617px #fff,
    1863px 2429px #fff, 507px 474px #fff, 1564px 168px #fff, 1758px 2180px #fff,
    1127px 1453px #fff, 2539px 88px #fff, 507px 1735px #fff, 909px 1540px #fff,
    2275px 1885px #fff, 62px 1077px #fff, 1826px 1198px #fff, 664px 1717px #fff,
    1522px 1562px #fff, 38px 1419px #fff, 1925px 1516px #fff, 283px 1939px #fff,
    1199px 1319px #fff, 384px 2547px #fff, 1304px 1027px #fff,
    2531px 1450px #fff, 597px 2472px #fff, 1127px 1566px #fff,
    1554px 2463px #fff, 1050px 1969px #fff, 667px 1496px #fff, 848px 486px #fff,
    764px 1165px #fff, 617px 539px #fff, 1956px 591px #fff, 1413px 1111px #fff,
    2422px 1165px #fff, 2183px 2440px #fff, 2354px 33px #fff, 2338px 1256px #fff,
    1279px 2091px #fff, 1637px 1854px #fff, 435px 388px #fff, 2100px 2275px #fff,
    2255px 2465px #fff, 1165px 2006px #fff, 2371px 1615px #fff, 84px 1736px #fff,
    530px 399px #fff, 1028px 342px #fff, 191px 2077px #fff, 2224px 37px #fff,
    2133px 2248px #fff, 1211px 1057px #fff, 58px 1970px #fff, 2538px 1565px #fff,
    626px 2076px #fff, 2393px 2282px #fff, 2388px 352px #fff, 2033px 1766px #fff,
    1224px 281px #fff, 816px 730px #fff, 372px 222px #fff, 791px 1342px #fff,
    118px 307px #fff, 529px 361px #fff, 1702px 800px #fff, 2379px 1923px #fff,
    2412px 1954px #fff, 298px 2073px #fff, 1478px 2458px #fff,
    1386px 2403px #fff, 1106px 2255px #fff, 1184px 2160px #fff,
    1734px 411px #fff, 1341px 928px #fff, 1601px 1782px #fff, 1492px 190px #fff,
    371px 865px #fff, 1927px 1583px #fff, 1168px 263px #fff, 1418px 1151px #fff,
    2404px 702px #fff, 1571px 455px #fff, 2209px 2159px #fff, 468px 1031px #fff,
    341px 627px #fff, 772px 85px #fff, 1775px 1030px #fff, 1771px 1758px #fff,
    2466px 1611px #fff, 2484px 1590px #fff, 2323px 418px #fff, 628px 1279px #fff,
    708px 2441px #fff, 1948px 1304px #fff, 1570px 2283px #fff,
    1794px 1089px #fff, 1197px 2082px #fff, 1075px 72px #fff, 849px 1400px #fff,
    1004px 170px #fff, 1493px 2161px #fff, 98px 1963px #fff, 2037px 1461px #fff,
    1527px 432px #fff, 2153px 933px #fff, 1344px 823px #fff, 764px 1632px #fff,
    2401px 2000px #fff, 2126px 1571px #fff, 74px 1759px #fff, 478px 1456px #fff,
    2336px 927px #fff, 623px 2377px #fff, 954px 1920px #fff, 1156px 1114px #fff,
    123px 1070px #fff, 1236px 2392px #fff, 1179px 1466px #fff, 1138px 769px #fff,
    1985px 1116px #fff, 1558px 1556px #fff, 575px 1100px #fff, 2123px 862px #fff,
    661px 180px #fff, 1678px 1425px #fff, 256px 2475px #fff, 2037px 1404px #fff,
    357px 2353px #fff, 2075px 1302px #fff, 417px 1659px #fff, 1251px 990px #fff,
    2513px 1853px #fff, 2250px 890px #fff, 1053px 221px #fff, 756px 971px #fff,
    1909px 2299px #fff, 1558px 498px #fff, 1740px 411px #fff, 263px 1935px #fff,
    1827px 1823px #fff, 1177px 532px #fff, 2495px 97px #fff, 1693px 1906px #fff,
    1879px 782px #fff, 1018px 861px #fff, 2082px 1345px #fff, 961px 93px #fff,
    1092px 384px #fff, 110px 2193px #fff, 1366px 2212px #fff, 268px 318px #fff,
    496px 2209px #fff, 2393px 2039px #fff, 1186px 2050px #fff, 1094px 217px #fff,
    2416px 648px #fff, 649px 2059px #fff, 2431px 1603px #fff, 2017px 49px #fff,
    2237px 2306px #fff, 14px 2154px #fff, 941px 1290px #fff, 53px 1430px #fff,
    2516px 1139px #fff, 73px 1139px #fff, 1699px 866px #fff, 83px 1687px #fff,
    2203px 177px #fff, 1247px 424px #fff, 46px 232px #fff, 1015px 305px #fff,
    248px 584px #fff, 169px 1048px #fff, 668px 1734px #fff, 582px 1265px #fff,
    1736px 2386px #fff, 814px 2121px #fff, 1553px 1774px #fff,
    1505px 1020px #fff, 971px 1879px #fff, 1927px 2513px #fff, 47px 79px #fff,
    804px 2005px #fff, 190px 561px #fff, 1523px 45px #fff, 430px 2220px #fff,
    1577px 582px #fff, 679px 305px #fff, 1718px 1753px #fff, 1481px 616px #fff,
    1205px 1881px #fff, 1788px 1633px #fff, 464px 2103px #fff, 188px 407px #fff,
    243px 126px #fff, 1630px 779px #fff, 2384px 1467px #fff, 697px 352px #fff,
    348px 1574px #fff, 1054px 239px #fff, 2331px 1843px #fff, 1200px 934px #fff,
    1748px 879px #fff, 579px 2082px #fff, 2485px 466px #fff, 1126px 425px #fff,
    2043px 1071px #fff, 379px 1832px #fff, 524px 602px #fff, 2113px 1716px #fff,
    2149px 2406px #fff, 600px 471px #fff, 1584px 2464px #fff, 29px 2059px #fff,
    1069px 119px #fff, 758px 770px #fff, 1014px 1008px #fff, 138px 2362px #fff,
    812px 989px #fff, 2168px 2282px #fff, 2086px 1763px #fff, 1108px 701px #fff,
    1496px 52px #fff, 1812px 1862px #fff, 1548px 1343px #fff, 626px 402px #fff,
    1887px 321px #fff, 2527px 339px #fff, 1081px 1472px #fff, 524px 808px #fff,
    855px 1440px #fff, 2033px 375px #fff, 695px 1291px #fff, 937px 674px #fff,
    1796px 796px #fff, 1805px 2362px #fff, 1174px 1879px #fff,
    1779px 1179px #fff, 1876px 2286px #fff, 2520px 925px #fff, 2068px 302px #fff,
    1361px 1689px #fff, 2278px 2556px #fff, 2405px 1302px #fff,
    148px 2331px #fff, 64px 1702px #fff, 1892px 838px #fff, 479px 1022px #fff,
    433px 1414px #fff, 2518px 1349px #fff, 1041px 2439px #fff,
    1100px 2078px #fff, 2044px 1265px #fff, 1464px 1559px #fff,
    282px 2307px #fff, 388px 1242px #fff, 1818px 982px #fff, 691px 125px #fff,
    655px 892px #fff, 982px 2151px #fff, 1135px 915px #fff, 355px 689px #fff,
    519px 1931px #fff, 254px 1895px #fff, 1100px 1323px #fff, 1357px 1573px #fff,
    803px 92px #fff, 429px 739px #fff, 2528px 1855px #fff, 663px 2488px #fff,
    1373px 45px #fff, 656px 861px #fff, 673px 1005px #fff, 648px 125px #fff,
    109px 1719px #fff, 1466px 1791px #fff, 431px 550px #fff, 1655px 744px #fff,
    2345px 752px #fff, 1197px 746px #fff, 1468px 909px #fff, 930px 1709px #fff,
    2515px 1493px #fff, 96px 295px #fff, 2249px 976px #fff, 1176px 2089px #fff,
    859px 2307px #fff, 1610px 802px #fff, 1610px 1998px #fff, 2358px 812px #fff,
    1513px 201px #fff, 601px 33px #fff, 2050px 1086px #fff, 1606px 910px #fff,
    2463px 671px #fff, 706px 256px #fff, 2048px 1406px #fff, 2074px 390px #fff,
    1109px 671px #fff, 257px 193px #fff, 213px 2015px #fff, 382px 1256px #fff,
    1883px 2102px #fff, 2515px 155px #fff, 1388px 132px #fff, 2374px 2558px #fff,
    560px 463px #fff, 323px 1186px #fff, 1157px 853px #fff, 840px 861px #fff,
    1727px 1196px #fff, 97px 1418px #fff, 748px 317px #fff, 178px 1002px #fff,
    548px 197px #fff, 1725px 2530px #fff, 1827px 2373px #fff, 681px 2044px #fff,
    2237px 2171px #fff, 312px 2433px #fff, 364px 2026px #fff, 2395px 2103px #fff,
    1109px 1589px #fff, 936px 2064px #fff, 2044px 1802px #fff,
    2522px 1238px #fff, 1020px 32px #fff, 2100px 1925px #fff, 54px 62px #fff,
    2459px 906px #fff, 1940px 1418px #fff, 1398px 2132px #fff, 1018px 22px #fff,
    594px 2187px #fff, 1713px 1412px #fff, 100px 1409px #fff, 2398px 1931px #fff,
    1392px 2172px #fff, 2036px 30px #fff, 270px 1199px #fff, 2380px 1686px #fff,
    907px 1191px #fff, 2081px 1976px #fff, 815px 865px #fff, 1358px 826px #fff,
    2336px 1254px #fff, 1503px 2003px #fff, 775px 772px #fff, 1886px 464px #fff,
    1572px 2499px #fff, 339px 2240px #fff, 1707px 1972px #fff, 2039px 9px #fff,
    1163px 749px #fff, 1414px 540px #fff, 175px 1134px #fff, 1296px 1381px #fff,
    12px 1139px #fff, 884px 751px #fff, 35px 898px #fff, 1219px 1513px #fff,
    2177px 2513px #fff, 1248px 1080px #fff, 336px 2488px #fff, 1938px 486px #fff,
    1471px 632px #fff, 1930px 1523px #fff, 246px 1198px #fff, 945px 2410px #fff,
    627px 1382px #fff, 800px 954px #fff, 1617px 955px #fff, 2324px 1402px #fff,
    183px 324px #fff, 2163px 1201px #fff, 317px 2252px #fff, 1500px 647px #fff,
    1076px 2305px #fff, 1390px 1648px #fff, 105px 1886px #fff,
    1183px 1997px #fff, 2378px 1584px #fff, 312px 56px #fff, 743px 1781px #fff,
    1831px 2026px #fff, 865px 1954px #fff, 2020px 1744px #fff,
    2058px 2113px #fff, 771px 2142px #fff, 106px 339px #fff, 700px 1818px #fff,
    842px 1022px #fff, 46px 523px #fff, 2181px 18px #fff, 1752px 667px #fff,
    383px 607px #fff, 377px 930px #fff, 1862px 1313px #fff, 1684px 1927px #fff,
    1824px 1884px #fff, 1347px 2414px #fff, 15px 242px #fff, 492px 2498px #fff,
    284px 2495px #fff, 1739px 521px #fff, 1031px 2223px #fff, 788px 1257px #fff,
    1038px 2450px #fff, 1129px 700px #fff, 1969px 1442px #fff,
    2170px 1535px #fff, 135px 703px #fff, 530px 792px #fff, 2105px 87px #fff,
    912px 62px #fff, 1802px 1592px #fff, 743px 1000px #fff, 273px 1174px #fff,
    2545px 2430px #fff, 233px 570px #fff, 1746px 2226px #fff, 797px 1392px #fff,
    1752px 2502px #fff, 449px 1210px #fff, 1887px 2549px #fff,
    2264px 1413px #fff, 1873px 2365px #fff, 259px 2542px #fff, 2140px 625px #fff,
    1375px 2200px #fff, 1654px 1002px #fff, 1332px 847px #fff, 376px 472px #fff,
    1586px 730px #fff, 60px 631px #fff, 2517px 38px #fff, 193px 2222px #fff,
    1452px 624px #fff, 1061px 2456px #fff, 1285px 1534px #fff,
    1370px 1945px #fff, 2485px 1694px #fff, 2032px 1518px #fff,
    1738px 1369px #fff, 1660px 244px #fff, 1990px 2005px #fff, 1538px 6px #fff,
    703px 717px #fff, 1294px 971px #fff, 1191px 962px #fff, 1469px 2041px #fff,
    1428px 216px #fff, 485px 2556px #fff, 2031px 589px #fff, 2255px 2421px #fff,
    1985px 1128px #fff, 1311px 1876px #fff, 701px 40px #fff, 1971px 116px #fff,
    1192px 904px #fff, 899px 1764px #fff, 1822px 1580px #fff, 498px 1999px #fff,
    1649px 1039px #fff, 185px 384px #fff, 362px 1587px #fff, 1360px 753px #fff,
    859px 1933px #fff, 508px 1262px #fff, 2112px 853px #fff, 1073px 553px #fff,
    831px 430px #fff, 1361px 2286px #fff, 334px 875px #fff, 978px 2144px #fff,
    625px 695px #fff, 1377px 81px #fff, 1010px 734px #fff, 2203px 1281px #fff,
    1064px 2489px #fff, 1811px 599px #fff, 746px 2292px #fff, 2475px 605px #fff,
    1919px 1408px #fff, 1688px 1774px #fff, 1745px 1995px #fff,
    1873px 2296px #fff, 662px 1866px #fff, 2351px 1470px #fff, 1905px 984px #fff,
    1494px 1689px #fff, 2060px 359px #fff, 2362px 1247px #fff, 2030px 1px #fff,
    2062px 1701px #fff, 1961px 1592px #fff, 2536px 716px #fff, 1963px 798px #fff,
    1256px 936px #fff, 383px 880px #fff, 1425px 1797px #fff, 1594px 1574px #fff,
    675px 1826px #fff, 1450px 435px #fff, 425px 2292px #fff, 44px 1117px #fff,
    330px 87px #fff, 1469px 638px #fff, 2180px 2508px #fff, 2410px 1504px #fff,
    446px 135px #fff, 2044px 1631px #fff, 930px 765px #fff, 978px 1482px #fff,
    1201px 184px #fff, 1421px 2212px #fff, 826px 1395px #fff, 2069px 1331px #fff,
    378px 789px #fff, 448px 2498px #fff, 703px 34px #fff, 1737px 59px #fff,
    1949px 1285px #fff, 2537px 1237px #fff, 2502px 1865px #fff,
    1293px 621px #fff, 1506px 741px #fff, 1217px 723px #fff, 837px 2511px #fff,
    2162px 1225px #fff, 1259px 1102px #fff, 927px 10px #fff, 454px 979px #fff,
    1187px 801px #fff, 2439px 1277px #fff, 672px 2102px #fff, 685px 316px #fff,
    2214px 1316px #fff, 1041px 368px #fff, 1518px 1219px #fff, 465px 2174px #fff,
    594px 1542px #fff, 1486px 1172px #fff, 1545px 2107px #fff, 161px 2421px #fff,
    572px 2312px #fff, 951px 949px #fff, 1033px 1114px #fff, 941px 2541px #fff,
    196px 2506px #fff, 1717px 1906px #fff, 1477px 969px #fff, 1725px 451px #fff,
    1943px 2354px #fff, 500px 1176px #fff, 792px 1863px #fff, 2374px 1116px #fff,
    1969px 2294px #fff, 2494px 2037px #fff, 1737px 1755px #fff,
    514px 2138px #fff, 1850px 1376px #fff, 1203px 2013px #fff, 489px 2240px #fff,
    560px 966px #fff, 1788px 1628px #fff, 1581px 465px #fff, 606px 2135px #fff,
    2342px 673px #fff, 1445px 1648px #fff, 245px 686px #fff, 252px 120px #fff,
    569px 2130px #fff, 994px 153px #fff, 465px 2008px #fff, 891px 273px #fff,
    2427px 1747px #fff, 1931px 1592px #fff, 2347px 1476px #fff,
    2357px 1294px #fff, 1427px 1109px #fff, 2515px 533px #fff, 654px 1721px #fff,
    2485px 767px #fff, 1661px 1162px #fff, 1054px 1344px #fff, 2322px 165px #fff,
    492px 889px #fff, 1497px 1223px #fff, 2033px 1470px #fff, 1318px 565px #fff,
    1049px 1618px #fff, 1779px 698px #fff, 1443px 865px #fff, 1475px 2174px #fff,
    2098px 1874px #fff, 1870px 1272px #fff, 1381px 32px #fff, 2159px 696px #fff,
    1957px 1011px #fff, 471px 1423px #fff, 2385px 1928px #fff, 312px 1935px #fff,
    448px 507px #fff, 784px 2192px #fff, 986px 172px #fff, 1768px 615px #fff,
    191px 2533px #fff, 363px 1090px #fff, 998px 914px #fff, 1282px 1721px #fff,
    1754px 1303px #fff, 881px 2092px #fff, 2216px 2077px #fff, 1178px 703px #fff,
    703px 469px #fff, 1213px 255px #fff, 326px 2336px #fff, 416px 602px #fff,
    125px 37px #fff, 82px 2303px #fff, 757px 351px #fff, 343px 2374px #fff,
    1661px 2346px #fff, 1112px 963px #fff, 2194px 865px #fff, 629px 886px #fff,
    1409px 1749px #fff, 1812px 2163px #fff, 1383px 1725px #fff,
    2420px 1246px #fff, 14px 1087px #fff, 99px 930px #fff, 417px 381px #fff,
    1244px 1435px #fff, 1741px 1549px #fff, 1015px 2201px #fff,
    2202px 2129px #fff, 12px 599px #fff, 2141px 2505px #fff, 2341px 6px #fff,
    148px 1564px #fff, 315px 722px #fff, 802px 123px #fff, 463px 2272px #fff,
    1212px 1519px #fff, 980px 322px #fff, 1165px 105px #fff, 436px 495px #fff,
    1900px 1711px #fff, 1878px 855px #fff, 507px 1940px #fff, 31px 2180px #fff,
    1539px 86px #fff, 300px 1682px #fff, 2287px 1601px #fff, 1345px 2367px #fff,
    89px 2555px #fff, 2033px 1473px #fff, 448px 2553px #fff, 2013px 2429px #fff,
    2178px 1755px #fff, 1939px 1039px #fff, 258px 242px #fff, 21px 2399px #fff,
    494px 2261px #fff, 1920px 76px #fff, 300px 727px #fff, 1537px 12px #fff,
    764px 1997px #fff, 701px 578px #fff, 1376px 124px #fff, 94px 316px #fff,
    2326px 2056px #fff, 1836px 1999px #fff, 2155px 1191px #fff,
    1763px 459px #fff, 436px 1071px #fff, 839px 997px #fff, 855px 360px #fff,
    276px 937px #fff, 207px 73px #fff, 44px 2181px #fff, 965px 531px #fff,
    2265px 1418px #fff, 648px 1179px #fff, 163px 1206px #fff, 735px 644px #fff,
    852px 1268px #fff, 2433px 2124px #fff, 1746px 670px #fff, 2380px 2105px #fff,
    1159px 2352px #fff, 361px 671px #fff, 746px 228px #fff, 2404px 586px #fff,
    2139px 1431px #fff, 597px 2543px #fff, 1068px 1971px #fff, 741px 2541px #fff,
    1387px 570px #fff, 2268px 1514px #fff, 1758px 1585px #fff,
    1372px 1621px #fff, 1119px 382px #fff, 642px 500px #fff, 1957px 1271px #fff,
    1931px 2385px #fff, 2391px 2092px #fff, 998px 1762px #fff, 238px 1200px #fff,
    1132px 330px #fff, 770px 852px #fff, 1031px 1062px #fff, 811px 2091px #fff,
    1958px 1412px #fff, 1386px 654px #fff, 2172px 1072px #fff, 1394px 169px #fff,
    2510px 1666px #fff, 1516px 2210px #fff, 173px 67px #fff, 1262px 2164px #fff,
    1986px 1594px #fff, 877px 247px #fff, 2423px 2343px #fff, 1292px 1054px #fff,
    1201px 515px #fff, 205px 2018px #fff, 1700px 2353px #fff, 2287px 1473px #fff,
    2516px 1714px #fff, 1269px 385px #fff, 760px 324px #fff, 2128px 948px #fff,
    2040px 120px #fff, 180px 506px #fff, 1337px 1067px #fff, 2185px 1576px #fff,
    2467px 1584px #fff, 1668px 2432px #fff, 2312px 2169px #fff,
    1186px 757px #fff, 1157px 1354px #fff, 108px 1175px #fff, 2109px 831px #fff,
    112px 2142px #fff, 780px 843px #fff, 1067px 2155px #fff, 748px 931px #fff,
    2557px 129px #fff, 256px 1572px #fff, 1726px 1956px #fff, 1240px 756px #fff,
    708px 1977px #fff, 995px 1653px #fff, 360px 423px #fff, 1965px 987px #fff,
    2448px 2276px #fff, 952px 670px #fff, 1097px 2137px #fff, 2321px 1848px #fff,
    2373px 829px #fff, 1159px 1149px #fff, 830px 868px #fff, 290px 464px #fff,
    1161px 2242px #fff, 2437px 1328px #fff, 917px 827px #fff, 877px 83px #fff,
    2431px 353px #fff, 1535px 870px #fff, 1806px 1599px #fff, 1963px 1255px #fff,
    1019px 1408px #fff, 287px 1798px #fff, 929px 2557px #fff, 2473px 1531px #fff,
    2492px 1852px #fff, 2351px 1166px #fff, 857px 538px #fff, 1081px 2390px #fff,
    2544px 1179px #fff, 1958px 2023px #fff, 1053px 927px #fff, 301px 1496px #fff,
    285px 1257px #fff, 591px 1518px #fff, 2451px 1621px #fff, 1130px 1734px #fff,
    1685px 1309px #fff, 2404px 2434px #fff, 449px 2222px #fff, 2374px 151px #fff,
    2508px 969px #fff, 1055px 1375px #fff, 1371px 82px #fff, 1727px 507px #fff,
    1631px 1976px #fff, 1425px 1458px #fff, 2351px 1694px #fff,
    477px 1421px #fff, 2425px 813px #fff, 241px 2383px #fff, 2384px 2082px #fff,
    2396px 714px #fff, 2312px 199px #fff, 696px 2138px #fff, 1060px 905px #fff,
    1587px 1579px #fff, 2113px 471px #fff, 168px 293px #fff, 243px 1576px #fff,
    323px 1090px #fff, 143px 2457px #fff, 2511px 2225px #fff, 1514px 2286px #fff,
    1031px 432px #fff, 115px 2324px #fff, 1px 1900px #fff, 1204px 1953px #fff,
    1846px 1969px #fff, 96px 2317px #fff, 1450px 2441px #fff, 2284px 143px #fff,
    398px 1951px #fff, 212px 290px #fff, 1715px 787px #fff, 1427px 305px #fff,
    1579px 1979px #fff, 968px 1285px #fff, 1380px 2389px #fff,
    2029px 1114px #fff, 1065px 1789px #fff, 503px 2248px #fff, 928px 1286px #fff,
    318px 2410px #fff, 1521px 1211px #fff, 1625px 1446px #fff,
    1139px 2479px #fff, 2204px 2440px #fff, 606px 1551px #fff,
    1127px 2550px #fff, 1255px 140px #fff, 1890px 842px #fff, 1019px 114px #fff,
    1399px 694px #fff, 2031px 1114px #fff, 604px 55px #fff, 2420px 848px #fff,
    1808px 2258px #fff, 2418px 414px #fff, 503px 2171px #fff, 1617px 139px #fff,
    324px 1641px #fff, 505px 1379px #fff, 1440px 974px #fff, 2029px 471px #fff,
    2478px 2368px #fff, 790px 861px #fff, 758px 1033px #fff, 1731px 2059px #fff,
    1659px 2537px #fff, 2517px 2359px #fff, 2548px 765px #fff, 558px 1041px #fff,
    1634px 342px #fff, 724px 790px #fff, 1533px 1384px #fff, 1507px 259px #fff,
    1097px 1770px #fff, 1364px 762px #fff, 888px 379px #fff, 2036px 1638px #fff,
    421px 808px #fff, 2029px 2529px #fff, 857px 176px #fff, 1335px 1978px #fff,
    1039px 512px #fff, 2245px 1758px #fff, 520px 2452px #fff, 139px 2384px #fff,
    2519px 2418px #fff, 781px 1148px #fff, 1934px 956px #fff, 241px 1066px #fff,
    2330px 2367px #fff, 139px 423px #fff, 1572px 1247px #fff, 2097px 2428px #fff,
    2012px 1885px #fff, 1957px 407px #fff, 16px 1162px #fff, 1116px 602px #fff,
    1909px 286px #fff, 1183px 48px #fff, 1439px 58px #fff, 731px 226px #fff,
    1296px 1912px #fff, 2559px 81px #fff, 1447px 428px #fff, 1618px 897px #fff,
    898px 2494px #fff, 1747px 1957px #fff, 2551px 1143px #fff,
    1008px 1208px #fff, 796px 1460px #fff, 222px 97px #fff, 2168px 1305px #fff,
    750px 1584px #fff, 171px 420px #fff, 1224px 930px #fff, 244px 1670px #fff,
    2173px 173px #fff, 2168px 991px #fff, 1550px 2521px #fff, 286px 874px #fff,
    769px 1435px #fff, 2474px 1872px #fff, 738px 759px #fff, 692px 1726px #fff,
    1706px 1146px #fff, 2411px 2127px #fff, 772px 454px #fff, 2520px 2310px #fff,
    2176px 335px #fff, 1581px 2490px #fff, 1891px 2332px #fff,
    2391px 1323px #fff, 756px 2151px #fff, 449px 851px #fff, 318px 1947px #fff,
    12px 2054px #fff, 1988px 905px #fff, 2421px 1560px #fff, 2130px 1075px #fff,
    1016px 889px #fff, 1806px 1293px #fff, 1057px 718px #fff, 1565px 565px #fff,
    1077px 749px #fff, 585px 2395px #fff, 2320px 120px #fff, 1483px 2374px #fff,
    1419px 2464px #fff, 2336px 2411px #fff, 1343px 747px #fff, 378px 260px #fff,
    1337px 1953px #fff, 2515px 1910px #fff, 309px 1327px #fff, 2082px 715px #fff,
    1615px 2216px #fff, 2161px 2161px #fff, 818px 2365px #fff, 2206px 848px #fff,
    1709px 1146px #fff, 242px 197px #fff, 102px 1278px #fff, 1023px 2232px #fff,
    1112px 410px #fff, 1063px 908px #fff, 2270px 74px #fff, 188px 1339px #fff,
    858px 477px #fff, 1820px 1033px #fff, 691px 345px #fff, 537px 1923px #fff,
    1330px 1609px #fff, 2478px 813px #fff;
  animation: animStar 100s linear infinite;
}
.stars:after {
  content: " ";
  top: -600px;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  position: absolute;
  backgroud: transparent;
  box-shadow: 672px 691px #fff, 590px 1360px #fff, 807px 2188px #fff,
    1094px 1022px #fff, 1736px 1637px #fff, 823px 277px #fff, 2193px 33px #fff,
    78px 2554px #fff, 1323px 1973px #fff, 2347px 601px #fff, 755px 1815px #fff,
    2300px 625px #fff, 2183px 1048px #fff, 1132px 1637px #fff,
    2108px 2157px #fff, 1707px 1773px #fff, 1965px 384px #fff, 689px 682px #fff,
    41px 729px #fff, 1159px 1063px #fff, 1681px 2282px #fff, 673px 2013px #fff,
    735px 1399px #fff, 2434px 1331px #fff, 684px 2341px #fff, 387px 2533px #fff,
    233px 292px #fff, 2349px 1924px #fff, 96px 2241px #fff, 2024px 2286px #fff,
    2153px 508px #fff, 2511px 1471px #fff, 71px 155px #fff, 409px 1679px #fff,
    300px 875px #fff, 2083px 1339px #fff, 2546px 44px #fff, 525px 162px #fff,
    1422px 1811px #fff, 2235px 2247px #fff, 2067px 632px #fff, 2315px 473px #fff,
    2518px 2094px #fff, 2090px 518px #fff, 1266px 1237px #fff, 2081px 836px #fff,
    439px 1663px #fff, 1566px 1216px #fff, 842px 2144px #fff, 1865px 1207px #fff,
    1791px 748px #fff, 380px 2021px #fff, 1523px 703px #fff, 1028px 2345px #fff,
    612px 909px #fff, 668px 56px #fff, 953px 1261px #fff, 2085px 348px #fff,
    665px 234px #fff, 240px 1642px #fff, 990px 1092px #fff, 1067px 1051px #fff,
    452px 1735px #fff, 384px 2449px #fff, 1727px 328px #fff, 959px 693px #fff,
    1625px 826px #fff, 2261px 2040px #fff, 613px 671px #fff, 2199px 2478px #fff,
    2297px 1017px #fff, 989px 942px #fff, 1990px 2253px #fff, 1724px 1066px #fff,
    476px 933px #fff, 2073px 1360px #fff, 540px 375px #fff, 811px 627px #fff,
    948px 2168px #fff, 961px 2297px #fff, 1217px 952px #fff, 1659px 607px #fff,
    914px 15px #fff, 773px 1565px #fff, 531px 1827px #fff, 1190px 1386px #fff,
    1601px 1851px #fff, 1644px 2292px #fff, 2420px 437px #fff, 826px 391px #fff,
    2215px 2465px #fff, 1038px 2319px #fff, 333px 1009px #fff, 461px 1000px #fff,
    928px 2421px #fff, 1766px 1128px #fff, 628px 720px #fff, 2056px 922px #fff,
    153px 1964px #fff, 2163px 659px #fff, 157px 950px #fff, 2023px 1368px #fff,
    784px 2354px #fff, 173px 1460px #fff, 28px 2043px #fff, 1918px 335px #fff,
    2251px 2310px #fff, 912px 2462px #fff, 1326px 2092px #fff, 2066px 827px #fff,
    1781px 2197px #fff, 1558px 200px #fff, 2518px 1714px #fff,
    1234px 1554px #fff, 2276px 1825px #fff, 439px 424px #fff, 151px 43px #fff,
    152px 2512px #fff, 1048px 1500px #fff, 1636px 1319px #fff,
    1277px 1436px #fff, 731px 2039px #fff, 424px 1549px #fff, 1975px 1653px #fff,
    2026px 1207px #fff, 43px 1699px #fff, 1322px 686px #fff, 38px 1109px #fff,
    1042px 277px #fff, 1986px 1175px #fff, 2342px 1411px #fff, 398px 361px #fff,
    1956px 521px #fff, 619px 1353px #fff, 1016px 514px #fff, 795px 2390px #fff,
    154px 2411px #fff, 51px 1214px #fff, 334px 2519px #fff, 401px 2096px #fff,
    158px 1419px #fff, 1765px 17px #fff, 1331px 1653px #fff, 1806px 915px #fff,
    728px 46px #fff, 994px 79px #fff, 1677px 1323px #fff, 352px 825px #fff,
    42px 1231px #fff, 1702px 2538px #fff, 343px 867px #fff, 1758px 1729px #fff,
    419px 77px #fff, 2143px 2493px #fff, 2042px 2116px #fff, 1139px 367px #fff,
    1968px 1886px #fff, 75px 1908px #fff, 49px 2064px #fff, 2303px 1229px #fff,
    2220px 1657px #fff, 1739px 36px #fff, 2418px 1590px #fff, 1807px 1424px #fff,
    460px 1183px #fff, 1361px 244px #fff, 2200px 551px #fff, 2093px 390px #fff,
    1609px 370px #fff, 1072px 620px #fff, 1110px 2141px #fff, 749px 2436px #fff,
    263px 1106px #fff, 1807px 1141px #fff, 1361px 2524px #fff, 386px 325px #fff,
    221px 2421px #fff, 1720px 908px #fff, 1707px 1716px #fff, 350px 1594px #fff,
    180px 1635px #fff, 1333px 114px #fff, 1929px 1793px #fff, 2364px 2234px #fff,
    2323px 2359px #fff, 1290px 72px #fff, 1887px 1107px #fff, 2238px 137px #fff,
    307px 1082px #fff, 2124px 226px #fff, 1630px 1603px #fff, 374px 1937px #fff,
    1552px 113px #fff, 2282px 83px #fff, 565px 2211px #fff, 1893px 1660px #fff,
    1738px 2546px #fff, 2323px 2017px #fff, 2426px 451px #fff, 603px 764px #fff,
    2423px 1140px #fff, 2252px 1834px #fff, 1010px 536px #fff,
    1280px 1707px #fff, 95px 1766px #fff, 1552px 1458px #fff, 1345px 510px #fff,
    1849px 285px #fff, 188px 2491px #fff, 1288px 1286px #fff, 1608px 260px #fff,
    1693px 535px #fff, 99px 1982px #fff, 2430px 2296px #fff, 797px 124px #fff,
    1575px 850px #fff, 1912px 732px #fff, 2372px 1097px #fff, 1804px 58px #fff,
    353px 857px #fff, 629px 2216px #fff, 772px 133px #fff, 407px 1588px #fff,
    1318px 847px #fff, 2113px 2063px #fff, 2349px 1473px #fff, 149px 78px #fff,
    1079px 1927px #fff, 1268px 283px #fff, 182px 2073px #fff, 28px 2019px #fff,
    2px 1994px #fff, 1511px 369px #fff, 218px 293px #fff, 677px 761px #fff,
    1560px 2048px #fff, 134px 890px #fff, 632px 1119px #fff, 898px 2181px #fff,
    1511px 1557px #fff, 1599px 1383px #fff, 2375px 2348px #fff,
    2050px 864px #fff, 2061px 1427px #fff, 2072px 1551px #fff,
    1726px 1623px #fff, 713px 513px #fff, 106px 1235px #fff, 1495px 1640px #fff,
    1773px 240px #fff, 193px 1147px #fff, 907px 476px #fff, 322px 164px #fff,
    517px 2117px #fff, 466px 1255px #fff, 2513px 116px #fff, 2441px 1114px #fff,
    2094px 2358px #fff, 2026px 2022px #fff, 2391px 2528px #fff,
    239px 1342px #fff, 1074px 422px #fff, 41px 604px #fff, 1446px 738px #fff,
    14px 2029px #fff, 2446px 846px #fff, 1327px 1076px #fff, 1099px 993px #fff,
    1874px 218px #fff, 2381px 225px #fff, 2318px 807px #fff, 1813px 1090px #fff,
    910px 2396px #fff, 1809px 601px #fff, 1227px 2398px #fff, 1547px 1601px #fff,
    1062px 2549px #fff, 1701px 861px #fff, 284px 2522px #fff, 2230px 1897px #fff,
    1107px 1802px #fff, 1397px 1620px #fff, 2169px 1005px #fff,
    2432px 1051px #fff, 1625px 1074px #fff, 2459px 741px #fff, 353px 1828px #fff,
    974px 1731px #fff, 580px 1989px #fff, 464px 1200px #fff, 1094px 1544px #fff,
    1119px 502px #fff, 2363px 1825px #fff, 1147px 125px #fff, 35px 1099px #fff,
    74px 847px #fff, 2496px 2138px #fff, 1430px 556px #fff, 2053px 1069px #fff,
    961px 378px #fff, 1665px 2479px #fff, 1794px 2080px #fff, 1993px 705px #fff,
    1301px 135px #fff, 910px 1935px #fff, 1610px 121px #fff, 1096px 2330px #fff,
    1215px 1410px #fff, 2010px 1862px #fff, 466px 2425px #fff,
    1255px 2365px #fff, 404px 2498px #fff, 294px 901px #fff, 919px 1886px #fff,
    737px 2034px #fff, 2430px 1838px #fff, 642px 296px #fff, 1604px 1445px #fff,
    1495px 184px #fff, 1860px 125px #fff, 2165px 2155px #fff, 1664px 1867px #fff,
    972px 1654px #fff, 159px 299px #fff, 1074px 31px #fff, 1669px 90px #fff,
    930px 2032px #fff, 1107px 813px #fff, 1782px 360px #fff, 1454px 902px #fff,
    1021px 2146px #fff, 1820px 678px #fff, 2303px 763px #fff, 934px 2128px #fff,
    296px 854px #fff, 2071px 1131px #fff, 1677px 1750px #fff, 254px 1980px #fff,
    1702px 2420px #fff, 2211px 1843px #fff, 1440px 1528px #fff,
    1852px 1772px #fff, 1913px 1761px #fff, 1233px 1451px #fff,
    2403px 752px #fff, 375px 911px #fff, 289px 1747px #fff, 1635px 577px #fff,
    819px 172px #fff, 282px 1067px #fff, 2176px 2171px #fff, 1133px 558px #fff,
    790px 405px #fff, 2066px 2261px #fff, 478px 87px #fff, 2225px 341px #fff,
    417px 2488px #fff, 453px 1712px #fff, 638px 1534px #fff, 1022px 872px #fff,
    2487px 432px #fff, 2439px 1666px #fff, 955px 1451px #fff, 171px 67px #fff,
    1781px 346px #fff, 35px 2498px #fff, 2104px 1020px #fff, 1274px 1208px #fff,
    873px 587px #fff, 2443px 2034px #fff, 2260px 501px #fff, 2553px 340px #fff,
    2457px 1463px #fff, 1018px 297px #fff, 2009px 1917px #fff, 1761px 24px #fff,
    1275px 1475px #fff, 844px 635px #fff, 2008px 317px #fff, 1420px 1680px #fff,
    904px 560px #fff, 495px 1585px #fff, 2399px 847px #fff, 589px 2231px #fff,
    1362px 711px #fff, 280px 1276px #fff, 930px 50px #fff, 95px 1927px #fff,
    507px 1376px #fff, 730px 2043px #fff, 1082px 1299px #fff, 1961px 1036px #fff,
    1294px 539px #fff, 2094px 2253px #fff, 1445px 1912px #fff, 1788px 815px #fff,
    2234px 944px #fff, 1439px 1898px #fff, 614px 1185px #fff, 1011px 2408px #fff,
    1117px 1696px #fff, 1224px 1233px #fff, 1723px 1704px #fff,
    1372px 649px #fff, 2250px 1301px #fff, 325px 2221px #fff, 2404px 2283px #fff,
    541px 2472px #fff, 2022px 414px #fff, 690px 341px #fff, 1658px 2333px #fff,
    2507px 176px #fff, 954px 1055px #fff, 598px 741px #fff, 1059px 1538px #fff,
    56px 480px #fff, 2348px 2108px #fff, 1649px 647px #fff, 295px 1890px #fff,
    1358px 1054px #fff, 1098px 1723px #fff, 289px 657px #fff, 497px 1591px #fff,
    1696px 1808px #fff, 1321px 2420px #fff, 2142px 1962px #fff, 147px 498px #fff,
    987px 1328px #fff, 598px 1807px #fff, 1423px 2025px #fff, 707px 1272px #fff,
    1270px 2431px #fff, 113px 1740px #fff, 522px 1464px #fff, 525px 833px #fff,
    398px 1705px #fff, 323px 1845px #fff, 1954px 2188px #fff, 365px 970px #fff,
    329px 509px #fff, 1073px 2391px #fff, 915px 778px #fff, 2426px 284px #fff,
    1433px 1462px #fff, 1537px 1096px #fff, 332px 1516px #fff, 2272px 710px #fff,
    1191px 1091px #fff, 2138px 354px #fff, 2326px 38px #fff, 1142px 1310px #fff,
    2028px 871px #fff, 1519px 934px #fff, 1890px 2086px #fff, 1090px 2214px #fff,
    1047px 1061px #fff, 613px 534px #fff, 116px 584px #fff, 1183px 2217px #fff,
    1159px 1504px #fff, 2425px 163px #fff, 1997px 2235px #fff, 635px 1725px #fff,
    2039px 1615px #fff, 2205px 30px #fff, 1450px 1636px #fff, 546px 2358px #fff,
    1845px 8px #fff, 1075px 1074px #fff, 542px 496px #fff, 2485px 505px #fff,
    2289px 1469px #fff, 2323px 753px #fff, 704px 766px #fff, 1391px 1569px #fff,
    310px 852px #fff, 1572px 971px #fff, 768px 34px #fff, 1772px 996px #fff,
    1630px 443px #fff, 1228px 1274px #fff, 2085px 1157px #fff,
    2253px 2452px #fff, 2269px 706px #fff, 761px 1046px #fff, 1773px 1559px #fff,
    1575px 1526px #fff, 102px 2327px #fff, 685px 1473px #fff, 82px 596px #fff,
    499px 798px #fff, 1512px 744px #fff, 1523px 403px #fff, 628px 2046px #fff,
    1372px 1009px #fff, 2530px 503px #fff, 342px 58px #fff, 687px 1933px #fff,
    1012px 1238px #fff, 280px 838px #fff, 1726px 1658px #fff, 1954px 280px #fff,
    27px 826px #fff, 2550px 718px #fff, 1295px 134px #fff, 1862px 107px #fff,
    1942px 1889px #fff, 914px 1548px #fff, 484px 2514px #fff, 2180px 2399px #fff,
    967px 2228px #fff, 501px 1851px #fff, 2186px 613px #fff, 605px 812px #fff,
    2301px 980px #fff, 1680px 1052px #fff, 2322px 2487px #fff, 126px 431px #fff,
    438px 1716px #fff, 123px 361px #fff, 711px 378px #fff, 26px 1112px #fff,
    2205px 1080px #fff, 1100px 1783px #fff, 1399px 186px #fff,
    2145px 1992px #fff, 2394px 509px #fff, 542px 2552px #fff, 337px 1410px #fff,
    2361px 1782px #fff, 1581px 1440px #fff, 1674px 829px #fff, 488px 1415px #fff,
    2033px 2371px #fff, 2187px 1855px #fff, 1450px 1004px #fff,
    2530px 2262px #fff, 2005px 883px #fff, 1003px 1523px #fff,
    1580px 1758px #fff, 2231px 1731px #fff, 555px 402px #fff, 920px 891px #fff,
    2068px 2151px #fff, 821px 1px #fff, 1673px 2251px #fff, 2124px 2295px #fff,
    2417px 1824px #fff, 1234px 501px #fff, 2546px 1964px #fff, 1351px 196px #fff,
    2038px 1647px #fff, 1817px 964px #fff, 922px 1131px #fff, 1038px 1513px #fff,
    2183px 1954px #fff, 2216px 166px #fff, 230px 355px #fff, 1333px 1603px #fff,
    193px 868px #fff, 1717px 57px #fff, 1817px 1428px #fff, 1164px 1819px #fff,
    296px 1890px #fff, 1271px 2369px #fff, 1770px 1567px #fff, 499px 325px #fff,
    49px 446px #fff, 754px 241px #fff, 2350px 1331px #fff, 1930px 2195px #fff,
    2038px 1850px #fff, 1783px 372px #fff, 1027px 1830px #fff,
    2557px 1849px #fff, 28px 2512px #fff, 2260px 2373px #fff, 2144px 707px #fff,
    1880px 2067px #fff, 1245px 2176px #fff, 1065px 1345px #fff,
    803px 1332px #fff, 2073px 796px #fff, 2431px 2030px #fff, 382px 1815px #fff,
    2525px 202px #fff, 928px 1576px #fff, 2416px 2423px #fff, 2394px 1567px #fff,
    540px 2353px #fff, 1149px 1595px #fff, 1943px 619px #fff, 1990px 2110px #fff,
    1818px 1418px #fff, 1156px 1501px #fff, 1425px 1308px #fff,
    2184px 1807px #fff, 502px 1763px #fff, 564px 1448px #fff, 2404px 2335px #fff,
    1760px 459px #fff, 1563px 1693px #fff, 2121px 1817px #fff, 372px 842px #fff,
    25px 758px #fff, 2431px 1441px #fff, 2248px 1661px #fff, 456px 1432px #fff,
    2341px 135px #fff, 2394px 2170px #fff, 2259px 1413px #fff,
    1383px 1547px #fff, 2150px 743px #fff, 1461px 2506px #fff, 2464px 502px #fff,
    631px 2169px #fff, 1181px 2185px #fff, 2504px 493px #fff, 1437px 1735px #fff,
    1093px 1619px #fff, 69px 1511px #fff, 1944px 1399px #fff, 2498px 2266px #fff,
    2553px 1766px #fff, 2126px 653px #fff, 905px 2534px #fff, 1138px 538px #fff,
    1596px 425px #fff, 213px 1040px #fff, 1761px 1534px #fff, 1466px 1598px #fff,
    2498px 486px #fff, 2163px 614px #fff, 241px 1617px #fff, 2213px 1779px #fff,
    125px 477px #fff, 1263px 709px #fff, 2206px 1011px #fff, 689px 2197px #fff,
    1505px 1920px #fff, 1398px 244px #fff, 2435px 1919px #fff,
    2431px 2329px #fff, 1556px 1914px #fff, 344px 2121px #fff, 69px 13px #fff,
    1291px 1628px #fff, 1037px 2466px #fff, 2212px 285px #fff, 723px 1938px #fff,
    2006px 2139px #fff, 198px 2066px #fff, 2078px 409px #fff, 448px 2405px #fff,
    1444px 2210px #fff, 1803px 1809px #fff, 639px 1218px #fff, 506px 1958px #fff,
    714px 660px #fff, 48px 15px #fff, 1879px 1765px #fff, 1383px 1516px #fff,
    964px 1220px #fff, 1559px 581px #fff, 2074px 1798px #fff, 2441px 128px #fff,
    1341px 1px #fff, 1942px 1350px #fff, 268px 2122px #fff, 2427px 1276px #fff,
    1222px 2175px #fff, 615px 1421px #fff, 997px 11px #fff, 1011px 1109px #fff,
    1392px 1464px #fff, 2030px 1537px #fff, 1769px 2231px #fff,
    2507px 1142px #fff, 1410px 319px #fff, 2082px 849px #fff, 1310px 2406px #fff,
    1832px 1678px #fff, 393px 714px #fff, 813px 1394px #fff, 1796px 98px #fff,
    2146px 2420px #fff, 1456px 739px #fff, 1980px 2210px #fff, 929px 2418px #fff,
    681px 669px #fff, 1639px 29px #fff, 841px 2541px #fff, 990px 666px #fff,
    709px 170px #fff, 170px 2349px #fff, 1842px 2261px #fff, 1868px 715px #fff,
    2539px 2347px #fff, 674px 28px #fff, 503px 1606px #fff, 2450px 1293px #fff,
    196px 1768px #fff, 2417px 248px #fff, 794px 1641px #fff, 2463px 2386px #fff,
    639px 2121px #fff, 211px 968px #fff, 1827px 2198px #fff, 1357px 1006px #fff,
    1570px 84px #fff, 1903px 530px #fff, 623px 1768px #fff, 150px 2473px #fff,
    196px 2300px #fff, 1327px 1679px #fff, 2521px 2336px #fff, 792px 1183px #fff,
    2533px 1915px #fff, 911px 1850px #fff, 1062px 1408px #fff, 485px 1909px #fff,
    1953px 1281px #fff, 2280px 673px #fff, 2419px 1546px #fff,
    2031px 1776px #fff, 1060px 1024px #fff, 1118px 115px #fff,
    1117px 2528px #fff, 2007px 1584px #fff, 1924px 90px #fff, 776px 231px #fff,
    2196px 1923px #fff, 230px 1808px #fff, 1897px 1673px #fff,
    1412px 1876px #fff, 1122px 740px #fff, 1081px 1214px #fff, 1081px 44px #fff,
    2342px 1572px #fff, 1716px 514px #fff, 1634px 1029px #fff, 905px 140px #fff,
    22px 1783px #fff, 1768px 2263px #fff, 1583px 1387px #fff, 1960px 2374px #fff,
    591px 1992px #fff, 1458px 921px #fff, 1447px 1196px #fff, 402px 1649px #fff,
    1034px 1405px #fff, 246px 2373px #fff, 1629px 1206px #fff,
    2317px 1740px #fff, 1909px 1135px #fff, 431px 2010px #fff, 2210px 322px #fff,
    1008px 90px #fff, 2538px 1464px #fff, 2044px 1191px #fff, 998px 2044px #fff,
    1250px 866px #fff, 1926px 1109px #fff, 116px 1662px #fff, 424px 953px #fff,
    524px 1960px #fff, 976px 515px #fff, 858px 2164px #fff, 1799px 1757px #fff,
    875px 1569px #fff, 2082px 468px #fff, 285px 6px #fff, 268px 1982px #fff,
    241px 1575px #fff, 1671px 1098px #fff, 757px 2442px #fff, 1162px 2004px #fff,
    427px 626px #fff, 161px 949px #fff, 2196px 382px #fff, 1971px 1117px #fff,
    1736px 1173px #fff, 1994px 2529px #fff, 2361px 1839px #fff, 740px 94px #fff,
    822px 193px #fff, 1251px 2062px #fff, 167px 2468px #fff, 1196px 170px #fff,
    1159px 1611px #fff, 1755px 1243px #fff, 1836px 828px #fff, 224px 737px #fff,
    1151px 1477px #fff, 961px 2037px #fff, 1155px 2378px #fff,
    1723px 1348px #fff, 89px 846px #fff, 2093px 1260px #fff, 914px 2445px #fff,
    876px 2497px #fff, 1637px 2335px #fff, 2475px 2003px #fff, 1990px 860px #fff,
    1613px 2123px #fff, 1733px 662px #fff, 2174px 1451px #fff,
    1513px 2212px #fff, 2559px 1738px #fff, 2131px 1839px #fff,
    925px 1846px #fff, 825px 741px #fff, 1489px 252px #fff, 575px 993px #fff,
    2037px 1401px #fff, 845px 727px #fff, 1680px 1952px #fff, 1700px 1203px #fff,
    1445px 650px #fff, 163px 1704px #fff, 144px 64px #fff, 775px 815px #fff,
    1163px 2464px #fff, 676px 1157px #fff, 2411px 2044px #fff, 944px 1064px #fff,
    589px 609px #fff, 1191px 1511px #fff, 1213px 2192px #fff, 330px 1548px #fff,
    1219px 287px #fff, 2520px 1283px #fff, 2021px 317px #fff, 2443px 680px #fff,
    52px 845px #fff, 1676px 2554px #fff, 342px 2147px #fff, 2109px 1428px #fff,
    2107px 2352px #fff, 1169px 1600px #fff, 40px 1091px #fff, 1598px 1786px #fff,
    881px 1414px #fff, 1865px 769px #fff, 1269px 1065px #fff, 1718px 1142px #fff,
    793px 1893px #fff, 1571px 736px #fff, 1332px 1049px #fff, 1834px 244px #fff,
    1235px 1393px #fff, 1741px 1488px #fff, 1116px 1211px #fff,
    2090px 1405px #fff, 2194px 2328px #fff, 1412px 224px #fff,
    2479px 2151px #fff, 437px 407px #fff, 1869px 1742px #fff, 151px 2057px #fff,
    924px 2116px #fff, 375px 158px #fff, 2318px 699px #fff, 2005px 786px #fff,
    1631px 2354px #fff, 1073px 1677px #fff, 1446px 1828px #fff,
    487px 2049px #fff, 1125px 612px #fff, 373px 1698px #fff, 587px 2210px #fff,
    1004px 1920px #fff, 475px 2272px #fff, 2229px 960px #fff, 1614px 1212px #fff,
    306px 1577px #fff, 1619px 1614px #fff, 1944px 1872px #fff, 418px 1324px #fff,
    997px 1525px #fff, 1048px 1685px #fff, 501px 962px #fff, 229px 1316px #fff,
    1993px 1680px #fff, 1722px 2216px #fff, 923px 711px #fff, 2302px 2168px #fff,
    1938px 2182px #fff, 704px 1760px #fff, 901px 817px #fff, 790px 1837px #fff,
    840px 1958px #fff, 2409px 1982px #fff, 1667px 1488px #fff, 1810px 459px #fff,
    1709px 1299px #fff, 1675px 2061px #fff, 2174px 1852px #fff,
    1503px 604px #fff, 1973px 1349px #fff, 1396px 2500px #fff, 309px 2061px #fff,
    1019px 2136px #fff, 1480px 906px #fff, 1796px 1797px #fff,
    1165px 1656px #fff, 2331px 871px #fff, 180px 1368px #fff, 1403px 153px #fff,
    1338px 969px #fff, 1463px 2480px #fff, 2545px 1547px #fff,
    2162px 1493px #fff, 343px 1166px #fff, 917px 36px #fff, 1955px 2151px #fff,
    689px 1242px #fff, 2141px 2557px #fff, 702px 2198px #fff, 1168px 2074px #fff,
    458px 887px #fff, 149px 1703px #fff, 528px 2420px #fff, 2086px 689px #fff,
    234px 2242px #fff, 1714px 948px #fff, 238px 1484px #fff, 1534px 1718px #fff,
    1349px 554px #fff, 32px 2548px #fff, 1809px 846px #fff, 2417px 1577px #fff,
    1440px 1439px #fff, 2459px 1306px #fff, 389px 1310px #fff,
    1358px 1810px #fff, 146px 37px #fff, 996px 1331px #fff, 1303px 617px #fff,
    1216px 456px #fff, 1844px 144px #fff, 1016px 2156px #fff, 656px 69px #fff,
    1472px 1775px #fff, 458px 1173px #fff, 1858px 645px #fff, 97px 283px #fff,
    20px 223px #fff, 1964px 1661px #fff, 2458px 1325px #fff, 1170px 1622px #fff,
    538px 944px #fff, 686px 2258px #fff, 1607px 123px #fff, 264px 753px #fff,
    1905px 1800px #fff, 1089px 1947px #fff, 265px 134px #fff, 1072px 1265px #fff,
    2329px 705px #fff, 2530px 1372px #fff, 284px 345px #fff, 1515px 2157px #fff,
    1519px 1297px #fff, 725px 673px #fff, 1724px 61px #fff, 389px 1376px #fff,
    90px 753px #fff, 1322px 694px #fff, 210px 1012px #fff, 1796px 1398px #fff,
    776px 438px #fff, 1614px 1626px #fff, 498px 847px #fff, 1871px 2162px #fff,
    2138px 1019px #fff, 696px 1536px #fff, 2386px 1130px #fff,
    1778px 1026px #fff, 1404px 2362px #fff, 545px 90px #fff, 13px 1003px #fff,
    1858px 2284px #fff, 1786px 2323px #fff, 2530px 1045px #fff,
    1127px 1117px #fff, 2207px 963px #fff, 431px 2px #fff, 191px 1469px #fff,
    410px 92px #fff, 2089px 151px #fff, 12px 2267px #fff, 1779px 913px #fff,
    1974px 684px #fff, 966px 2406px #fff, 436px 172px #fff, 399px 2506px #fff,
    1381px 651px #fff, 2382px 136px #fff, 1972px 1525px #fff, 785px 825px #fff,
    1182px 2557px #fff, 1146px 1663px #fff, 350px 815px #fff, 1018px 322px #fff,
    886px 2273px #fff, 247px 1977px #fff, 2123px 1252px #fff, 221px 1039px #fff,
    1871px 1502px #fff, 1379px 856px #fff, 851px 405px #fff, 1617px 1852px #fff,
    902px 1360px #fff, 2279px 1198px #fff, 1939px 1697px #fff, 913px 350px #fff,
    157px 217px #fff, 1845px 619px #fff, 2256px 3px #fff, 1939px 1795px #fff,
    424px 1354px #fff, 2251px 643px #fff, 202px 1876px #fff, 375px 912px #fff,
    988px 1630px #fff, 2253px 204px #fff, 1714px 878px #fff, 2165px 1272px #fff,
    1058px 1535px #fff, 12px 2164px #fff, 1101px 2252px #fff, 526px 2108px #fff,
    324px 1287px #fff, 316px 960px #fff, 671px 662px #fff, 1021px 2486px #fff,
    2397px 285px #fff, 2194px 1731px #fff, 2107px 2203px #fff, 106px 867px #fff,
    715px 558px #fff, 163px 2265px #fff, 1263px 605px #fff, 593px 482px #fff,
    318px 1630px #fff, 878px 926px #fff, 658px 1786px #fff, 1491px 819px #fff,
    990px 2135px #fff, 1338px 2342px #fff, 852px 860px #fff, 1405px 1782px #fff,
    271px 1262px #fff, 1448px 2083px #fff, 2197px 1048px #fff, 453px 1140px #fff,
    2541px 2397px #fff, 1612px 2533px #fff, 625px 964px #fff, 840px 2263px #fff,
    1254px 1715px #fff, 662px 1625px #fff, 463px 1961px #fff, 15px 1393px #fff,
    37px 697px #fff, 9px 2362px #fff, 712px 894px #fff, 1822px 653px #fff,
    1550px 2193px #fff, 1671px 536px #fff, 1626px 642px #fff, 1880px 1092px #fff,
    1993px 348px #fff, 1603px 1311px #fff, 1818px 170px #fff, 485px 684px #fff,
    1385px 1065px #fff, 2138px 355px #fff, 27px 592px #fff, 1058px 2403px #fff,
    1652px 100px #fff, 669px 2046px #fff, 834px 1146px #fff, 1350px 2065px #fff,
    383px 1577px #fff, 1967px 1531px #fff, 6px 1776px #fff, 329px 2207px #fff,
    876px 716px #fff, 1993px 565px #fff, 406px 2082px #fff, 2014px 917px #fff,
    1555px 961px #fff, 266px 684px #fff, 282px 1256px #fff, 1062px 2366px #fff,
    2177px 2436px #fff, 1912px 1465px #fff, 2013px 122px #fff, 942px 2538px #fff,
    1929px 2462px #fff, 1220px 2110px #fff, 101px 2524px #fff, 273px 1927px #fff,
    192px 333px #fff, 1951px 60px #fff, 980px 2179px #fff, 157px 951px #fff,
    2345px 1685px #fff, 768px 1249px #fff, 1255px 117px #fff, 2489px 2258px #fff,
    581px 1254px #fff, 1506px 307px #fff, 273px 1646px #fff, 1518px 2289px #fff,
    326px 1348px #fff, 1673px 755px #fff, 1667px 2415px #fff, 883px 1643px #fff,
    2311px 89px #fff, 1777px 1164px #fff, 1516px 399px #fff, 2425px 309px #fff,
    2121px 730px #fff, 39px 392px #fff, 1161px 1982px #fff, 2002px 696px #fff,
    556px 1571px #fff, 2368px 2418px #fff, 2053px 1023px #fff, 1802px 414px #fff,
    19px 1261px #fff, 2008px 1576px #fff, 2524px 1426px #fff, 2471px 2359px #fff,
    1973px 1578px #fff, 997px 499px #fff, 320px 685px #fff, 1855px 681px #fff,
    1485px 1385px #fff, 2013px 1305px #fff, 1575px 500px #fff,
    1303px 2001px #fff, 1069px 984px #fff, 221px 1103px #fff, 327px 2532px #fff,
    20px 2259px #fff, 943px 2481px #fff, 799px 419px #fff, 1536px 660px #fff,
    2207px 474px #fff, 1987px 1088px #fff, 311px 2466px #fff, 1174px 1370px #fff,
    595px 106px #fff, 654px 106px #fff, 2163px 100px #fff, 2135px 1632px #fff,
    2168px 2408px #fff, 10px 1427px #fff, 442px 232px #fff, 235px 354px #fff,
    2477px 888px #fff, 840px 2091px #fff, 1392px 1131px #fff, 1676px 618px #fff,
    798px 932px #fff, 1189px 1878px #fff, 1842px 307px #fff, 6px 1454px #fff,
    1575px 535px #fff, 2297px 1390px #fff, 1440px 1512px #fff, 374px 433px #fff,
    1634px 2256px #fff, 1848px 2327px #fff, 1740px 2340px #fff, 265px 851px #fff,
    819px 807px #fff, 1467px 2323px #fff, 355px 940px #fff, 2291px 2357px #fff,
    901px 2116px #fff, 1526px 403px #fff, 349px 294px #fff, 516px 852px #fff,
    1155px 2161px #fff, 81px 2162px #fff, 1689px 1814px #fff, 1565px 76px #fff,
    1151px 232px #fff, 1395px 745px #fff, 1466px 747px #fff, 1514px 282px #fff,
    1796px 2056px #fff, 1273px 1618px #fff, 402px 1660px #fff,
    1657px 1324px #fff, 2407px 1189px #fff, 759px 354px #fff, 2044px 2070px #fff,
    1072px 842px #fff, 761px 5px #fff, 1815px 889px #fff, 1615px 751px #fff,
    1806px 2480px #fff, 1264px 611px #fff, 1822px 2406px #fff,
    1048px 2204px #fff, 442px 1076px #fff, 1228px 270px #fff, 1777px 2333px #fff,
    2313px 2100px #fff, 1185px 1193px #fff, 2227px 2477px #fff,
    1946px 1493px #fff, 1509px 1991px #fff, 1740px 1722px #fff,
    852px 1852px #fff, 2335px 202px #fff, 1737px 456px #fff, 2238px 251px #fff,
    1394px 831px #fff, 2119px 694px #fff, 1228px 695px #fff, 1189px 631px #fff,
    1187px 140px #fff, 1692px 921px #fff, 637px 2101px #fff, 2297px 485px #fff,
    2414px 1261px #fff, 2467px 26px #fff, 115px 1506px #fff, 1110px 1040px #fff,
    986px 2274px #fff, 2059px 2085px #fff, 331px 133px #fff, 358px 2078px #fff,
    1170px 2240px #fff, 739px 2322px #fff, 1656px 2279px #fff, 342px 1860px #fff,
    1884px 2056px #fff, 1523px 1428px #fff, 1060px 894px #fff, 1191px 609px #fff,
    1257px 2039px #fff, 329px 1473px #fff, 793px 1452px #fff, 1021px 71px #fff,
    1435px 695px #fff, 1183px 1572px #fff, 2139px 1037px #fff,
    1755px 1069px #fff, 90px 1161px #fff, 992px 1117px #fff, 1645px 454px #fff,
    522px 2427px #fff, 1522px 491px #fff, 2444px 1378px #fff, 2110px 1404px #fff,
    98px 1215px #fff, 151px 942px #fff, 1907px 805px #fff, 1044px 471px #fff,
    2425px 240px #fff, 653px 2487px #fff, 413px 1522px #fff, 909px 860px #fff,
    312px 1105px #fff, 988px 2002px #fff, 875px 391px #fff, 1814px 465px #fff,
    1225px 2553px #fff, 521px 1013px #fff, 197px 65px #fff, 1902px 131px #fff,
    1629px 1334px #fff, 713px 214px #fff, 1639px 709px #fff, 1817px 1701px #fff,
    376px 2269px #fff, 1782px 2292px #fff, 571px 110px #fff, 54px 1321px #fff,
    2083px 559px #fff, 982px 1265px #fff, 359px 2236px #fff, 1039px 1295px #fff,
    256px 1807px #fff, 748px 2318px #fff, 1260px 1219px #fff, 544px 2277px #fff,
    2241px 30px #fff, 1080px 1335px #fff, 2250px 840px #fff, 595px 672px #fff,
    894px 47px #fff, 986px 1242px #fff, 2058px 731px #fff, 777px 2289px #fff,
    1974px 1415px #fff, 2395px 215px #fff, 1348px 2406px #fff, 729px 579px #fff,
    763px 263px #fff, 914px 934px #fff, 1377px 2148px #fff, 1565px 1530px #fff,
    2385px 455px #fff, 1351px 1913px #fff, 2549px 2334px #fff,
    1392px 2082px #fff, 1999px 318px #fff, 2449px 726px #fff, 2494px 1783px #fff,
    2376px 1689px #fff, 241px 1339px #fff, 1457px 2470px #fff,
    2117px 1105px #fff, 399px 431px #fff, 854px 1381px #fff, 1070px 2071px #fff,
    2294px 2030px #fff, 1525px 2060px #fff, 704px 2324px #fff,
    2460px 2445px #fff, 1275px 854px #fff, 219px 1279px #fff, 1840px 438px #fff,
    2327px 833px #fff, 724px 112px #fff, 842px 114px #fff, 1798px 1799px #fff,
    1942px 1951px #fff, 298px 1587px #fff, 2095px 248px #fff, 1804px 293px #fff,
    136px 2334px #fff, 1270px 1625px #fff, 1663px 2247px #fff, 801px 1723px #fff,
    1365px 652px #fff, 1992px 1455px #fff, 173px 596px #fff, 705px 1731px #fff,
    1763px 2024px #fff, 1871px 2507px #fff, 2530px 522px #fff, 2503px 904px #fff,
    756px 985px #fff, 1389px 403px #fff, 2499px 640px #fff, 1767px 1189px #fff,
    1391px 2269px #fff, 2453px 874px #fff, 1390px 603px #fff, 621px 910px #fff,
    483px 190px #fff, 1798px 1240px #fff, 1806px 840px #fff, 2009px 2362px #fff,
    317px 547px #fff, 1712px 2417px #fff, 2432px 2247px #fff, 1023px 743px #fff,
    1992px 2373px #fff, 963px 227px #fff, 589px 125px #fff, 426px 979px #fff,
    139px 1302px #fff, 1567px 146px #fff, 844px 1721px #fff, 459px 2211px #fff,
    1651px 1943px #fff, 453px 517px #fff, 194px 1111px #fff, 245px 2044px #fff,
    2519px 959px #fff, 1465px 2074px #fff, 208px 332px #fff, 266px 2470px #fff,
    2304px 988px #fff, 2158px 1932px #fff, 100px 893px #fff, 498px 1457px #fff,
    1405px 676px #fff, 2457px 260px #fff, 1790px 887px #fff, 2002px 1302px #fff,
    1006px 267px #fff, 1827px 283px #fff, 503px 231px #fff, 1578px 814px #fff,
    1274px 661px #fff, 2349px 2345px #fff, 1470px 1580px #fff, 2549px 967px #fff,
    86px 1880px #fff, 1913px 1544px #fff, 886px 652px #fff, 310px 2455px #fff,
    2421px 1137px #fff, 1322px 217px #fff, 1068px 130px #fff, 1454px 1294px #fff,
    1438px 1172px #fff, 514px 902px #fff, 1068px 2414px #fff, 1315px 751px #fff,
    2037px 2323px #fff, 915px 1418px #fff, 996px 2521px #fff, 818px 681px #fff,
    1989px 1770px #fff, 2093px 1875px #fff, 21px 2289px #fff, 2053px 2069px #fff,
    2380px 2273px #fff, 211px 309px #fff, 674px 639px #fff, 79px 750px #fff,
    1482px 1004px #fff, 1240px 2397px #fff, 464px 165px #fff, 1676px 897px #fff,
    2258px 159px #fff, 1362px 2423px #fff, 58px 1878px #fff, 606px 681px #fff,
    2368px 45px #fff, 558px 1279px #fff, 1178px 1225px #fff, 1225px 1027px #fff,
    4px 136px #fff, 238px 838px #fff, 1182px 1629px #fff, 976px 567px #fff,
    751px 321px #fff, 552px 1078px #fff, 1040px 2310px #fff, 2066px 1897px #fff,
    1566px 2472px #fff, 559px 71px #fff, 1723px 897px #fff, 961px 20px #fff,
    2328px 791px #fff, 2373px 2381px #fff, 1639px 156px #fff, 1565px 1951px #fff,
    459px 469px #fff, 656px 1238px #fff, 2283px 2500px #fff, 741px 1351px #fff,
    270px 864px #fff, 1970px 67px #fff, 1559px 1492px #fff, 1761px 223px #fff,
    1328px 594px #fff, 339px 1626px #fff, 294px 1953px #fff, 1053px 212px #fff,
    654px 1823px #fff, 2108px 2518px #fff, 2467px 1241px #fff,
    1780px 1282px #fff, 2325px 2125px #fff, 2473px 1174px #fff,
    647px 2528px #fff, 1653px 1669px #fff, 2083px 2077px #fff,
    1678px 1989px #fff, 2201px 1110px #fff, 1293px 387px #fff, 322px 840px #fff,
    551px 712px #fff, 1190px 1992px #fff, 1974px 2224px #fff, 1929px 2070px #fff,
    1550px 1261px #fff, 767px 1889px #fff, 1594px 721px #fff, 897px 692px #fff,
    1951px 1818px #fff, 1947px 1942px #fff, 94px 2251px #fff, 1938px 768px #fff,
    218px 1981px #fff, 591px 1927px #fff, 2273px 2304px #fff, 294px 1841px #fff,
    905px 2246px #fff, 1249px 1574px #fff, 1789px 1103px #fff, 1765px 237px #fff,
    70px 2048px #fff, 2094px 1933px #fff, 1763px 1968px #fff, 2088px 1083px #fff,
    933px 1516px #fff, 1266px 545px #fff, 1357px 155px #fff, 1096px 1592px #fff,
    1302px 1231px #fff, 1845px 331px #fff, 2241px 1729px #fff,
    2232px 2436px #fff, 2546px 1559px #fff, 2294px 2498px #fff,
    1662px 1705px #fff, 970px 376px #fff, 2354px 1589px #fff, 1449px 1374px #fff,
    1530px 867px #fff, 56px 162px #fff, 1932px 1274px #fff, 1004px 883px #fff,
    277px 2429px #fff, 2301px 250px #fff, 55px 1290px #fff, 2137px 2544px #fff,
    362px 1230px #fff, 412px 1420px #fff, 2298px 916px #fff, 1508px 579px #fff,
    2143px 1920px #fff, 531px 1325px #fff, 1829px 310px #fff, 1237px 1124px #fff,
    2127px 2219px #fff, 828px 1175px #fff, 1051px 2342px #fff,
    2028px 2340px #fff, 1984px 918px #fff, 791px 1706px #fff, 1114px 2170px #fff,
    1776px 2486px #fff, 1433px 1790px #fff, 2302px 2465px #fff, 1466px 26px #fff,
    79px 390px #fff, 380px 1463px #fff, 347px 2154px #fff, 704px 1827px #fff,
    1521px 2291px #fff, 1998px 630px #fff, 1351px 2342px #fff,
    2469px 1037px #fff, 352px 2107px #fff, 1552px 1253px #fff, 78px 1666px #fff,
    1772px 1845px #fff, 2028px 1453px #fff, 111px 55px #fff, 1177px 835px #fff,
    1598px 2162px #fff, 2436px 1917px #fff, 334px 2455px #fff,
    1147px 2491px #fff, 575px 274px #fff, 255px 535px #fff, 336px 2272px #fff,
    2345px 1888px #fff, 1879px 1841px #fff, 522px 2018px #fff, 1013px 859px #fff,
    1534px 1217px #fff, 1389px 2072px #fff, 1025px 767px #fff, 2424px 498px #fff,
    87px 1648px #fff, 722px 482px #fff, 16px 1300px #fff, 1742px 794px #fff,
    231px 1487px #fff, 933px 1672px #fff, 425px 1526px #fff, 941px 2172px #fff,
    1px 1461px #fff, 1846px 2464px #fff, 14px 1445px #fff, 980px 258px #fff,
    1015px 2305px #fff, 1548px 1428px #fff, 2446px 1783px #fff,
    1743px 2450px #fff, 417px 699px #fff, 1516px 2381px #fff, 1392px 1646px #fff,
    1333px 1505px #fff, 592px 251px #fff, 834px 1665px #fff, 1793px 2441px #fff,
    593px 108px #fff, 530px 1152px #fff, 2118px 66px #fff, 1620px 1741px #fff,
    1745px 2371px #fff, 1914px 213px #fff, 112px 1008px #fff, 1175px 2243px #fff,
    1468px 1585px #fff, 2389px 1045px #fff, 2550px 1278px #fff,
    428px 1581px #fff, 65px 1835px #fff, 439px 1967px #fff, 838px 127px #fff,
    80px 1476px #fff, 1952px 1909px #fff, 791px 2292px #fff, 66px 1389px #fff,
    1181px 24px #fff, 71px 615px #fff, 1479px 595px #fff, 992px 2007px #fff,
    1776px 1772px #fff, 670px 2031px #fff, 353px 1545px #fff, 275px 1822px #fff,
    1720px 1174px #fff, 2040px 2066px #fff, 1198px 2312px #fff,
    2373px 546px #fff, 2507px 2036px #fff, 2430px 1288px #fff, 270px 1909px #fff,
    1709px 1365px #fff, 391px 496px #fff, 1079px 246px #fff, 492px 1543px #fff,
    2547px 1407px #fff, 395px 207px #fff, 2381px 2501px #fff, 2329px 1447px #fff,
    1032px 1356px #fff, 1295px 540px #fff, 486px 2156px #fff, 57px 4px #fff,
    2169px 1372px #fff, 1690px 1749px #fff, 2339px 2263px #fff,
    159px 2222px #fff, 246px 796px #fff, 1448px 346px #fff, 2087px 688px #fff,
    381px 324px #fff, 2269px 707px #fff, 970px 644px #fff, 594px 412px #fff,
    565px 727px #fff, 1377px 1730px #fff, 935px 1035px #fff, 919px 2466px #fff,
    1429px 2463px #fff, 2081px 2300px #fff, 1535px 108px #fff,
    1766px 2394px #fff, 2206px 1463px #fff, 155px 1486px #fff,
    2049px 1995px #fff, 2325px 1540px #fff, 871px 314px #fff, 836px 1870px #fff,
    269px 1848px #fff, 1877px 807px #fff, 700px 252px #fff, 2170px 999px #fff,
    1024px 1731px #fff, 597px 2363px #fff, 258px 2108px #fff, 1898px 1273px #fff,
    1699px 449px #fff, 1941px 1301px #fff, 1672px 1660px #fff,
    1279px 2285px #fff, 498px 668px #fff, 1400px 727px #fff, 36px 1093px #fff,
    2002px 2450px #fff, 531px 828px #fff, 766px 1142px #fff, 2053px 1781px #fff,
    2120px 2153px #fff, 1517px 2466px #fff, 185px 411px #fff, 1432px 2427px #fff,
    381px 2156px #fff, 1215px 1652px #fff, 2026px 2430px #fff, 56px 1118px #fff,
    98px 733px #fff, 1213px 2384px #fff, 718px 2196px #fff, 671px 2401px #fff,
    138px 2076px #fff, 106px 1617px #fff, 1368px 1142px #fff;
}

.stars1 {
  z-index: 10;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 2383px 2167px #fff, 2177px 487px #fff, 983px 357px #fff,
    849px 1830px #fff, 387px 688px #fff, 790px 1743px #fff, 471px 2043px #fff,
    1953px 398px #fff, 453px 440px #fff, 2039px 625px #fff, 296px 1815px #fff,
    2123px 110px #fff, 2157px 2305px #fff, 334px 1223px #fff, 191px 251px #fff,
    666px 257px #fff, 2383px 733px #fff, 1417px 70px #fff, 1046px 1727px #fff,
    602px 1595px #fff, 2035px 1580px #fff, 214px 257px #fff, 346px 198px #fff,
    1776px 682px #fff, 1965px 1867px #fff, 2535px 2145px #fff, 2092px 218px #fff,
    634px 763px #fff, 450px 1255px #fff, 1463px 1126px #fff, 2493px 1930px #fff,
    864px 719px #fff, 2256px 219px #fff, 933px 1538px #fff, 1646px 466px #fff,
    184px 2198px #fff, 1109px 2117px #fff, 2115px 1737px #fff,
    1604px 1922px #fff, 531px 188px #fff, 2052px 940px #fff, 1024px 610px #fff,
    725px 1041px #fff, 2073px 1806px #fff, 1367px 2465px #fff, 2023px 782px #fff,
    1979px 253px #fff, 819px 2440px #fff, 1260px 156px #fff, 2387px 678px #fff,
    305px 1436px #fff, 99px 353px #fff, 2299px 1963px #fff, 540px 587px #fff,
    2455px 776px #fff, 1105px 1422px #fff, 1230px 324px #fff, 422px 164px #fff,
    131px 1971px #fff, 985px 2494px #fff, 629px 189px #fff, 1086px 210px #fff,
    126px 2333px #fff, 1004px 2122px #fff, 131px 917px #fff, 638px 1024px #fff,
    229px 1843px #fff, 65px 737px #fff, 292px 1825px #fff, 1401px 657px #fff,
    726px 900px #fff, 1575px 1247px #fff, 2534px 1484px #fff, 1738px 63px #fff,
    1413px 2191px #fff, 1850px 2105px #fff, 2092px 1756px #fff,
    1989px 1070px #fff, 988px 886px #fff, 2374px 2488px #fff, 490px 1569px #fff,
    2018px 883px #fff, 1397px 323px #fff, 698px 423px #fff, 1194px 877px #fff,
    2101px 2505px #fff, 2144px 1535px #fff, 1027px 803px #fff, 1485px 142px #fff,
    1030px 2546px #fff, 442px 734px #fff, 1241px 627px #fff, 2306px 2342px #fff,
    1186px 888px #fff, 886px 351px #fff, 2076px 1824px #fff, 2408px 466px #fff,
    770px 2068px #fff, 676px 844px #fff, 1259px 435px #fff, 409px 1494px #fff,
    2544px 1317px #fff, 2511px 1901px #fff, 1890px 553px #fff, 1032px 41px #fff,
    499px 1772px #fff, 694px 795px #fff, 1772px 57px #fff, 1353px 2497px #fff,
    77px 1055px #fff, 914px 1795px #fff, 316px 1452px #fff, 430px 1724px #fff,
    1302px 355px #fff, 2266px 70px #fff, 116px 551px #fff, 1994px 216px #fff,
    2553px 1220px #fff, 2525px 836px #fff, 1714px 2008px #fff, 1096px 771px #fff,
    2268px 884px #fff, 1391px 1490px #fff, 912px 733px #fff, 182px 1321px #fff,
    658px 1077px #fff, 1943px 2017px #fff, 1426px 195px #fff, 492px 429px #fff,
    2494px 280px #fff, 650px 982px #fff, 999px 554px #fff, 2510px 1229px #fff,
    394px 1131px #fff, 1190px 816px #fff, 1085px 1310px #fff, 507px 587px #fff,
    111px 297px #fff, 652px 242px #fff, 1995px 1139px #fff, 1832px 827px #fff,
    1543px 2089px #fff, 5px 2px #fff, 1682px 771px #fff, 2362px 187px #fff,
    1170px 800px #fff, 288px 110px #fff, 2343px 1564px #fff, 347px 546px #fff,
    1943px 1230px #fff, 1490px 2261px #fff, 291px 1533px #fff, 775px 688px #fff,
    2547px 2374px #fff, 558px 865px #fff, 1871px 183px #fff, 2350px 2230px #fff,
    2284px 1751px #fff, 794px 1070px #fff, 1021px 842px #fff, 1244px 11px #fff,
    386px 1177px #fff, 1235px 1954px #fff, 1168px 349px #fff, 1633px 128px #fff,
    1182px 468px #fff, 1281px 1848px #fff, 2282px 1313px #fff, 886px 663px #fff,
    839px 2407px #fff, 2557px 1489px #fff, 2329px 474px #fff, 1110px 1927px #fff,
    1225px 1146px #fff, 2537px 2122px #fff, 1160px 400px #fff, 1643px 435px #fff,
    1668px 697px #fff, 1615px 767px #fff, 2509px 2229px #fff, 1051px 7px #fff,
    1732px 694px #fff, 2050px 349px #fff, 797px 1215px #fff, 668px 842px #fff,
    1823px 1323px #fff, 1374px 1720px #fff, 706px 314px #fff, 2475px 101px #fff,
    2434px 96px #fff, 1731px 2092px #fff, 1547px 1235px #fff, 1088px 577px #fff,
    2429px 2203px #fff, 589px 653px #fff, 2366px 2054px #fff, 448px 2256px #fff,
    24px 914px #fff, 493px 2364px #fff, 1994px 1781px #fff, 2486px 1390px #fff,
    1805px 2011px #fff, 570px 2401px #fff, 1869px 2224px #fff,
    2475px 1569px #fff, 687px 949px #fff, 1044px 197px #fff, 1569px 935px #fff,
    146px 2353px #fff, 2199px 2381px #fff, 1296px 1365px #fff, 1020px 295px #fff,
    1814px 111px #fff, 525px 1352px #fff, 568px 1382px #fff, 2255px 727px #fff,
    2083px 2223px #fff, 1667px 1614px #fff, 676px 2147px #fff, 738px 1277px #fff,
    2361px 2534px #fff, 520px 517px #fff, 427px 1482px #fff, 737px 2163px #fff,
    248px 2264px #fff, 1135px 1677px #fff, 948px 654px #fff, 480px 274px #fff,
    460px 2325px #fff, 1731px 2388px #fff, 193px 896px #fff, 474px 1461px #fff,
    2505px 1857px #fff, 1723px 615px #fff, 205px 1670px #fff, 727px 1659px #fff,
    2393px 218px #fff, 2023px 1327px #fff, 1394px 558px #fff, 14px 887px #fff,
    1721px 2261px #fff, 1903px 114px #fff, 656px 2012px #fff, 22px 162px #fff,
    1805px 1474px #fff, 1792px 712px #fff, 1757px 1425px #fff, 650px 1165px #fff,
    2302px 1521px #fff, 291px 2550px #fff, 1214px 821px #fff, 30px 2479px #fff,
    460px 173px #fff, 290px 611px #fff, 1833px 2357px #fff, 2278px 102px #fff,
    1028px 2444px #fff, 2156px 1057px #fff, 500px 508px #fff, 20px 1249px #fff,
    2325px 1339px #fff, 508px 1817px #fff, 1610px 350px #fff, 1323px 2076px #fff,
    1994px 998px #fff, 2551px 1525px #fff, 1458px 2147px #fff, 2154px 649px #fff,
    1778px 2278px #fff, 2212px 339px #fff, 640px 745px #fff, 393px 2483px #fff,
    1684px 1425px #fff, 557px 1284px #fff, 1318px 32px #fff, 128px 433px #fff,
    1356px 1031px #fff, 318px 424px #fff, 490px 2064px #fff, 543px 135px #fff,
    2550px 1714px #fff, 258px 1871px #fff, 1381px 481px #fff, 1132px 1512px #fff,
    57px 422px #fff, 399px 733px #fff, 1778px 67px #fff, 540px 1099px #fff,
    1670px 2279px #fff, 1970px 89px #fff, 638px 185px #fff, 202px 1776px #fff,
    674px 2318px #fff, 1577px 798px #fff, 1561px 2116px #fff, 1543px 1320px #fff,
    1985px 1152px #fff, 1802px 1509px #fff, 1314px 2020px #fff,
    671px 2268px #fff, 414px 2512px #fff, 1222px 1212px #fff, 1747px 207px #fff,
    1387px 1694px #fff, 702px 1656px #fff, 957px 375px #fff, 786px 1601px #fff,
    1457px 1521px #fff, 559px 1254px #fff, 2318px 2142px #fff,
    1710px 2448px #fff, 2187px 1987px #fff, 1227px 32px #fff, 1555px 2318px #fff,
    1078px 1615px #fff, 295px 2278px #fff, 671px 2048px #fff, 320px 1512px #fff,
    1319px 1482px #fff, 612px 1543px #fff, 1222px 597px #fff, 862px 385px #fff,
    108px 1235px #fff, 1379px 2539px #fff, 946px 536px #fff, 2423px 2169px #fff,
    577px 29px #fff, 1270px 1264px #fff, 1770px 720px #fff, 1192px 2026px #fff,
    360px 2513px #fff, 941px 2315px #fff, 2407px 774px #fff, 2068px 1991px #fff,
    261px 383px #fff, 1188px 1445px #fff, 1559px 1819px #fff, 630px 1434px #fff,
    734px 1783px #fff, 1538px 2186px #fff, 370px 2000px #fff, 2129px 603px #fff,
    437px 1681px #fff, 1842px 2020px #fff, 2299px 1183px #fff, 926px 1581px #fff,
    893px 706px #fff, 1167px 871px #fff, 784px 1323px #fff, 2503px 1418px #fff,
    454px 2559px #fff, 1110px 1393px #fff, 687px 1732px #fff, 1246px 2076px #fff,
    2205px 1530px #fff, 253px 1353px #fff, 1672px 2464px #fff, 395px 720px #fff,
    63px 1794px #fff, 1309px 1556px #fff, 1871px 89px #fff, 2241px 2190px #fff,
    2197px 50px #fff, 129px 297px #fff, 2545px 1521px #fff, 877px 1064px #fff,
    473px 1002px #fff, 318px 969px #fff, 1543px 2253px #fff, 2522px 572px #fff,
    337px 1212px #fff, 1831px 1515px #fff, 1904px 1826px #fff, 571px 309px #fff,
    1499px 1377px #fff, 2418px 1021px #fff, 217px 2027px #fff, 165px 792px #fff,
    1438px 2039px #fff, 581px 1627px #fff, 2208px 1330px #fff, 1688px 88px #fff,
    2318px 1743px #fff, 1585px 1337px #fff, 1157px 1538px #fff,
    1556px 583px #fff, 1902px 2052px #fff, 2030px 2420px #fff, 2499px 345px #fff,
    268px 2465px #fff, 330px 1462px #fff, 2473px 2222px #fff, 1097px 540px #fff,
    463px 1336px #fff, 261px 2051px #fff, 179px 1882px #fff, 1059px 604px #fff,
    1215px 2069px #fff, 1412px 2345px #fff, 1518px 940px #fff, 322px 375px #fff,
    309px 2102px #fff, 164px 186px #fff, 2283px 851px #fff, 389px 960px #fff,
    695px 594px #fff, 1664px 2058px #fff, 1628px 1959px #fff, 994px 144px #fff,
    312px 465px #fff, 2498px 1294px #fff, 13px 2478px #fff, 1630px 2330px #fff,
    982px 108px #fff, 337px 1051px #fff, 2157px 2043px #fff, 158px 866px #fff,
    2374px 2527px #fff, 971px 1451px #fff, 1016px 1122px #fff,
    1833px 2362px #fff, 204px 577px #fff, 154px 1396px #fff, 2393px 1701px #fff,
    1824px 1583px #fff, 2485px 111px #fff, 1200px 897px #fff, 1555px 1205px #fff,
    1996px 578px #fff, 152px 994px #fff, 1877px 69px #fff, 728px 1125px #fff,
    824px 2343px #fff, 1182px 568px #fff, 2550px 2256px #fff, 97px 1954px #fff,
    1495px 991px #fff, 2182px 1993px #fff, 1967px 593px #fff, 2298px 287px #fff,
    2034px 790px #fff, 648px 1368px #fff, 758px 735px #fff, 2065px 1309px #fff,
    2503px 606px #fff, 1269px 1730px #fff, 1695px 1976px #fff,
    1184px 1809px #fff, 1470px 85px #fff, 1158px 1991px #fff, 1625px 129px #fff,
    1590px 420px #fff, 1795px 1501px #fff, 1914px 1099px #fff,
    2025px 1228px #fff, 140px 2309px #fff, 2137px 1722px #fff, 828px 1444px #fff,
    758px 615px #fff, 2138px 2006px #fff, 262px 1456px #fff, 1704px 2443px #fff,
    527px 1659px #fff, 2314px 1883px #fff, 2168px 1549px #fff, 2339px 812px #fff,
    1106px 1240px #fff, 2402px 2439px #fff, 1050px 1304px #fff, 980px 989px #fff,
    1365px 1625px #fff, 2011px 1461px #fff, 282px 2264px #fff, 1148px 963px #fff,
    591px 811px #fff, 685px 909px #fff, 2043px 891px #fff, 2320px 1527px #fff,
    1867px 1520px #fff, 285px 1177px #fff, 1829px 174px #fff, 1354px 61px #fff,
    1975px 328px #fff, 655px 2057px #fff, 1877px 1882px #fff, 2105px 21px #fff,
    1165px 541px #fff, 1461px 1914px #fff, 891px 1037px #fff, 1702px 1876px #fff,
    475px 2430px #fff, 240px 562px #fff, 2113px 1944px #fff, 1844px 566px #fff,
    889px 301px #fff, 1546px 1932px #fff, 1280px 1691px #fff, 2210px 2399px #fff,
    1068px 917px #fff, 197px 1779px #fff, 576px 951px #fff, 618px 1843px #fff,
    875px 2396px #fff, 1902px 2532px #fff, 648px 81px #fff, 1758px 1992px #fff,
    1442px 2165px #fff, 1847px 2231px #fff, 995px 2228px #fff,
    2215px 1426px #fff, 265px 890px #fff, 2108px 856px #fff, 121px 2357px #fff,
    2015px 1400px #fff, 447px 120px #fff, 458px 2044px #fff, 8px 2310px #fff,
    1185px 1712px #fff, 2041px 2502px #fff, 783px 1537px #fff,
    1170px 1855px #fff, 186px 679px #fff, 52px 1589px #fff, 1057px 533px #fff,
    127px 1697px #fff, 154px 2218px #fff, 14px 2104px #fff, 674px 322px #fff,
    1975px 1168px #fff, 864px 1795px #fff, 198px 427px #fff, 2019px 17px #fff,
    205px 1495px #fff, 1771px 1550px #fff, 756px 2357px #fff, 1026px 611px #fff,
    2085px 2485px #fff, 2534px 1335px #fff, 765px 1869px #fff, 163px 739px #fff,
    521px 1505px #fff, 510px 127px #fff, 682px 1517px #fff, 2022px 596px #fff,
    957px 1977px #fff, 904px 1179px #fff, 1301px 2360px #fff, 2382px 1957px #fff,
    1373px 2515px #fff, 1192px 1038px #fff, 1752px 635px #fff, 692px 1453px #fff,
    499px 487px #fff, 306px 2405px #fff, 100px 2222px #fff, 1220px 297px #fff,
    2343px 1498px #fff, 1152px 792px #fff, 464px 1786px #fff, 1600px 860px #fff,
    2439px 414px #fff, 1025px 2547px #fff, 1304px 1572px #fff, 1935px 898px #fff,
    293px 29px #fff, 1254px 1408px #fff, 2527px 2041px #fff, 1206px 1205px #fff,
    1952px 958px #fff, 558px 2449px #fff, 872px 654px #fff, 2236px 1893px #fff,
    358px 2550px #fff, 430px 1756px #fff, 1926px 1889px #fff, 758px 541px #fff,
    212px 827px #fff, 1683px 1398px #fff, 942px 2257px #fff, 2381px 71px #fff,
    1674px 36px #fff, 1781px 299px #fff, 2132px 2149px #fff, 1252px 2462px #fff,
    2225px 129px #fff, 1054px 567px #fff, 873px 1980px #fff, 1838px 1725px #fff,
    2481px 2489px #fff, 1809px 1384px #fff, 669px 238px #fff, 186px 2251px #fff,
    2546px 2386px #fff, 1890px 1123px #fff, 2033px 2338px #fff,
    1228px 332px #fff, 963px 194px #fff, 1902px 2225px #fff, 149px 192px #fff,
    1480px 63px #fff, 440px 229px #fff, 2403px 1525px #fff, 324px 1773px #fff,
    2164px 2519px #fff, 914px 97px #fff, 1057px 325px #fff, 205px 2021px #fff,
    2082px 1290px #fff, 2352px 1392px #fff, 951px 2350px #fff, 2418px 344px #fff,
    1501px 1256px #fff, 1934px 1342px #fff, 1342px 2122px #fff,
    1427px 1345px #fff, 993px 2008px #fff, 1139px 218px #fff, 151px 1862px #fff,
    2522px 2525px #fff, 1541px 1450px #fff, 1746px 902px #fff,
    2125px 2173px #fff, 1958px 1904px #fff, 971px 1194px #fff, 1143px 598px #fff,
    71px 60px #fff, 1867px 1549px #fff, 1177px 1500px #fff, 1264px 1080px #fff,
    1419px 2261px #fff, 2489px 986px #fff, 1944px 1631px #fff, 86px 2105px #fff,
    1856px 1907px #fff, 1247px 513px #fff, 2019px 2110px #fff,
    1256px 1741px #fff, 2379px 1802px #fff, 1067px 284px #fff, 363px 1685px #fff,
    1529px 2347px #fff, 1361px 1580px #fff, 1868px 759px #fff, 2504px 124px #fff,
    1888px 907px #fff, 2157px 1667px #fff, 398px 2079px #fff, 323px 1671px #fff,
    1432px 2344px #fff, 2242px 2394px #fff, 2217px 2054px #fff,
    1100px 1711px #fff, 2416px 1027px #fff, 228px 756px #fff, 1363px 337px #fff,
    1000px 1156px #fff, 497px 2255px #fff, 179px 2289px #fff, 1564px 1167px #fff,
    1334px 1850px #fff, 416px 340px #fff, 2499px 600px #fff, 1414px 674px #fff,
    410px 436px #fff, 886px 1670px #fff, 2133px 1288px #fff, 406px 1870px #fff,
    1546px 2280px #fff, 1700px 100px #fff, 1458px 1590px #fff, 892px 2280px #fff,
    1722px 2214px #fff, 1010px 1797px #fff, 929px 933px #fff, 248px 613px #fff,
    893px 1696px #fff, 118px 1735px #fff, 28px 256px #fff, 1862px 238px #fff,
    1774px 2092px #fff, 534px 2404px #fff, 163px 762px #fff, 904px 512px #fff,
    646px 412px #fff, 640px 194px #fff, 1930px 2131px #fff, 1165px 2355px #fff,
    343px 1282px #fff, 1365px 297px #fff, 1657px 2419px #fff, 591px 718px #fff,
    2160px 2524px #fff, 2099px 2319px #fff, 1343px 60px #fff, 576px 2078px #fff,
    1891px 781px #fff, 2559px 152px #fff, 875px 44px #fff, 2414px 214px #fff,
    35px 473px #fff;
  animation: animStar 125s linear infinite;
}
.stars1:after {
  content: " ";
  top: -600px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  position: absolute;
  backgroud: transparent;
  box-shadow: 1698px 1121px #fff, 1973px 757px #fff, 1304px 569px #fff,
    1525px 1591px #fff, 401px 1003px #fff, 1763px 2096px #fff, 1077px 791px #fff,
    951px 1670px #fff, 1409px 480px #fff, 47px 509px #fff, 1780px 727px #fff,
    1512px 620px #fff, 1560px 2022px #fff, 1335px 1841px #fff, 284px 1232px #fff,
    1107px 1802px #fff, 890px 1918px #fff, 2486px 1531px #fff, 1368px 194px #fff,
    2463px 125px #fff, 888px 392px #fff, 1707px 1548px #fff, 1868px 1963px #fff,
    2432px 1578px #fff, 25px 394px #fff, 755px 292px #fff, 2037px 1406px #fff,
    2504px 927px #fff, 73px 2020px #fff, 2235px 2436px #fff, 1647px 2206px #fff,
    1636px 2103px #fff, 2163px 1720px #fff, 589px 398px #fff, 161px 1817px #fff,
    1708px 720px #fff, 296px 898px #fff, 981px 2506px #fff, 1239px 2464px #fff,
    1561px 1471px #fff, 1208px 1311px #fff, 2282px 399px #fff,
    1230px 1686px #fff, 1844px 1998px #fff, 1501px 1718px #fff,
    2229px 1286px #fff, 2053px 1566px #fff, 531px 451px #fff, 1878px 1018px #fff,
    1817px 1236px #fff, 708px 1467px #fff, 456px 900px #fff, 2042px 872px #fff,
    247px 272px #fff, 1363px 770px #fff, 394px 1243px #fff, 910px 2100px #fff,
    2317px 419px #fff, 1496px 2445px #fff, 681px 1656px #fff, 835px 270px #fff,
    2100px 1005px #fff, 1748px 809px #fff, 2516px 926px #fff, 2185px 517px #fff,
    1905px 1166px #fff, 819px 1873px #fff, 1184px 1473px #fff,
    1376px 2082px #fff, 743px 1398px #fff, 828px 1252px #fff, 800px 2543px #fff,
    570px 401px #fff, 1133px 1742px #fff, 1519px 2113px #fff, 2441px 483px #fff,
    1975px 1386px #fff, 2351px 821px #fff, 484px 2398px #fff, 698px 775px #fff,
    2522px 2453px #fff, 2391px 1574px #fff, 1222px 1069px #fff,
    1491px 2467px #fff, 299px 1347px #fff, 1401px 731px #fff, 2450px 112px #fff,
    386px 1649px #fff, 1390px 2199px #fff, 770px 256px #fff, 102px 1298px #fff,
    2016px 711px #fff, 53px 1002px #fff, 1538px 204px #fff, 17px 1613px #fff,
    1965px 1826px #fff, 1569px 1897px #fff, 284px 408px #fff, 1146px 1053px #fff,
    1005px 786px #fff, 2144px 828px #fff, 865px 366px #fff, 967px 927px #fff,
    2410px 528px #fff, 1579px 1338px #fff, 1825px 1849px #fff, 2423px 32px #fff,
    2462px 2112px #fff, 2030px 2079px #fff, 657px 1090px #fff,
    2155px 1321px #fff, 1217px 997px #fff, 2304px 434px #fff, 2408px 81px #fff,
    540px 958px #fff, 1408px 972px #fff, 899px 2137px #fff, 1862px 2204px #fff,
    1780px 1546px #fff, 380px 568px #fff, 60px 1358px #fff, 2193px 1594px #fff,
    146px 304px #fff, 884px 2225px #fff, 388px 337px #fff, 1493px 2356px #fff,
    2217px 2441px #fff, 651px 501px #fff, 736px 1822px #fff, 1361px 2295px #fff,
    1150px 1928px #fff, 2507px 2155px #fff, 2091px 840px #fff,
    1924px 1909px #fff, 2540px 1538px #fff, 1721px 259px #fff, 1434px 412px #fff,
    2488px 2443px #fff, 969px 1327px #fff, 31px 2483px #fff, 1902px 2046px #fff,
    164px 1332px #fff, 412px 2334px #fff, 2040px 129px #fff, 1025px 1945px #fff,
    36px 448px #fff, 1253px 1578px #fff, 1335px 1917px #fff, 1269px 672px #fff,
    1858px 479px #fff, 2026px 2112px #fff, 1668px 2494px #fff, 424px 1143px #fff,
    708px 1387px #fff, 1545px 570px #fff, 1461px 2497px #fff, 1770px 1039px #fff,
    1588px 935px #fff, 2450px 1546px #fff, 2386px 273px #fff, 1966px 2315px #fff,
    1314px 2554px #fff, 1845px 101px #fff, 389px 835px #fff, 1334px 1005px #fff,
    2147px 2132px #fff, 1421px 601px #fff, 1082px 1344px #fff, 378px 2514px #fff,
    657px 576px #fff, 213px 563px #fff, 698px 1260px #fff, 666px 2106px #fff,
    1816px 659px #fff, 27px 1537px #fff, 1610px 2417px #fff, 1049px 76px #fff,
    415px 728px #fff, 516px 1588px #fff, 711px 2498px #fff, 1227px 487px #fff,
    2232px 2537px #fff, 264px 45px #fff, 1765px 1938px #fff, 1193px 1444px #fff,
    2290px 1313px #fff, 714px 486px #fff, 612px 1133px #fff, 1263px 2500px #fff,
    1131px 1200px #fff, 818px 73px #fff, 1234px 498px #fff, 944px 1423px #fff,
    899px 1266px #fff, 1437px 765px #fff, 1026px 441px #fff, 2084px 658px #fff,
    2546px 1052px #fff, 1042px 1936px #fff, 839px 694px #fff, 812px 348px #fff,
    2453px 987px #fff, 1557px 2228px #fff, 1717px 1330px #fff, 2528px 281px #fff,
    2016px 1012px #fff, 1685px 2151px #fff, 1033px 1602px #fff,
    2518px 2104px #fff, 2252px 1654px #fff, 1539px 1201px #fff,
    464px 2400px #fff, 1902px 57px #fff, 2323px 1539px #fff, 1412px 724px #fff,
    1977px 2113px #fff, 274px 871px #fff, 1096px 2308px #fff, 968px 2551px #fff,
    1995px 1336px #fff, 1717px 2356px #fff, 1711px 2419px #fff,
    1400px 1953px #fff, 2452px 694px #fff, 807px 334px #fff, 1417px 2185px #fff,
    756px 1122px #fff, 2123px 446px #fff, 1350px 2270px #fff, 1040px 971px #fff,
    1430px 1832px #fff, 1472px 527px #fff, 2137px 159px #fff, 1432px 1224px #fff,
    168px 1981px #fff, 1471px 371px #fff, 800px 77px #fff, 891px 479px #fff,
    605px 1768px #fff, 531px 2155px #fff, 1083px 590px #fff, 740px 1632px #fff,
    983px 2481px #fff, 2222px 226px #fff, 2061px 628px #fff, 809px 701px #fff,
    790px 315px #fff, 153px 893px #fff, 2317px 970px #fff, 2535px 2015px #fff,
    43px 560px #fff, 2403px 573px #fff, 853px 2290px #fff, 2297px 1932px #fff,
    1552px 721px #fff, 1771px 896px #fff, 1881px 401px #fff, 166px 1773px #fff,
    1501px 2295px #fff, 1383px 2474px #fff, 851px 329px #fff, 1604px 1192px #fff,
    2060px 1636px #fff, 808px 386px #fff, 2476px 2526px #fff, 60px 131px #fff,
    606px 2032px #fff, 1479px 1927px #fff, 2006px 2206px #fff, 1915px 300px #fff,
    907px 1441px #fff, 715px 958px #fff, 1819px 2086px #fff, 966px 650px #fff,
    282px 1057px #fff, 1576px 884px #fff, 1836px 1927px #fff, 1512px 950px #fff,
    62px 2178px #fff, 1119px 1414px #fff, 989px 1556px #fff, 1459px 731px #fff,
    1492px 2535px #fff, 2358px 1059px #fff, 550px 339px #fff, 889px 1738px #fff,
    1330px 361px #fff, 253px 283px #fff, 1407px 2415px #fff, 1228px 2495px #fff,
    191px 2031px #fff, 2133px 7px #fff, 1965px 705px #fff, 1587px 1555px #fff,
    1862px 2150px #fff, 1407px 1021px #fff, 1327px 237px #fff, 876px 1567px #fff,
    2336px 835px #fff, 2160px 274px #fff, 1830px 1535px #fff, 793px 1116px #fff,
    1416px 1573px #fff, 186px 2438px #fff, 2328px 246px #fff, 2455px 2510px #fff,
    1941px 188px #fff, 1003px 1512px #fff, 411px 1055px #fff, 1986px 1516px #fff,
    556px 940px #fff, 1965px 151px #fff, 1266px 2471px #fff, 2539px 1054px #fff,
    2504px 2233px #fff, 2469px 2060px #fff, 1137px 698px #fff, 1760px 702px #fff,
    330px 1569px #fff, 623px 1697px #fff, 2368px 451px #fff, 1667px 1775px #fff,
    1802px 1905px #fff, 230px 367px #fff, 973px 244px #fff, 1756px 2126px #fff,
    18px 357px #fff, 1677px 1881px #fff, 1869px 1576px #fff, 2427px 1654px #fff,
    1835px 103px #fff, 2401px 1257px #fff, 50px 203px #fff, 1671px 2180px #fff,
    2474px 1257px #fff, 933px 1877px #fff, 2334px 916px #fff, 648px 1645px #fff,
    1461px 1120px #fff, 1277px 1769px #fff, 109px 882px #fff, 2428px 2512px #fff,
    1723px 2501px #fff, 2342px 2317px #fff, 207px 1512px #fff, 2427px 903px #fff,
    1180px 1741px #fff, 877px 121px #fff, 1226px 1956px #fff, 1254px 1668px #fff,
    2459px 1983px #fff, 2188px 334px #fff, 838px 54px #fff, 1963px 946px #fff,
    1177px 2164px #fff, 1786px 466px #fff, 940px 1453px #fff, 2169px 381px #fff,
    559px 1894px #fff, 2027px 1647px #fff, 1640px 138px #fff, 2338px 2054px #fff,
    1424px 1883px #fff, 1473px 1917px #fff, 2463px 1739px #fff,
    2511px 2450px #fff, 1076px 1833px #fff, 216px 1501px #fff,
    2522px 2232px #fff, 1445px 2462px #fff, 2484px 281px #fff, 488px 2109px #fff,
    1658px 429px #fff, 2050px 355px #fff, 1346px 2520px #fff, 1148px 362px #fff,
    155px 1861px #fff, 1198px 1018px #fff, 1980px 2555px #fff, 248px 1785px #fff,
    797px 562px #fff, 140px 383px #fff, 1118px 1254px #fff, 799px 498px #fff,
    2256px 1411px #fff, 1055px 192px #fff, 2376px 1995px #fff, 123px 2356px #fff,
    466px 2064px #fff, 1907px 1475px #fff, 688px 2312px #fff, 1186px 354px #fff,
    887px 1040px #fff, 1326px 1531px #fff, 693px 1410px #fff, 205px 1927px #fff,
    1999px 482px #fff, 840px 2523px #fff, 1778px 815px #fff, 1934px 1767px #fff,
    2425px 1595px #fff, 2025px 1060px #fff, 1769px 1341px #fff,
    2538px 550px #fff, 1798px 1527px #fff, 1962px 300px #fff, 230px 1355px #fff,
    996px 114px #fff, 1608px 1963px #fff, 2193px 1788px #fff, 43px 954px #fff,
    981px 2348px #fff, 2096px 1896px #fff, 1172px 171px #fff, 1207px 1338px #fff,
    1101px 1446px #fff, 449px 2322px #fff, 909px 2523px #fff, 162px 2177px #fff,
    1627px 2353px #fff, 1602px 1775px #fff, 2464px 1892px #fff,
    921px 2448px #fff, 2015px 1718px #fff, 699px 743px #fff, 387px 498px #fff,
    1791px 1595px #fff, 905px 1208px #fff, 1179px 1450px #fff,
    1858px 1011px #fff, 1179px 559px #fff, 2092px 450px #fff, 2095px 2027px #fff,
    1996px 757px #fff, 1329px 2180px #fff, 1980px 1553px #fff,
    2361px 1681px #fff, 825px 304px #fff, 589px 2252px #fff, 2049px 2514px #fff,
    2253px 528px #fff, 2413px 1148px #fff, 1058px 226px #fff, 568px 1183px #fff,
    868px 438px #fff, 2494px 2178px #fff, 91px 2526px #fff, 1833px 394px #fff,
    13px 903px #fff, 343px 221px #fff, 2246px 518px #fff, 358px 1426px #fff,
    80px 88px #fff, 106px 672px #fff, 2120px 546px #fff, 1845px 2045px #fff,
    2198px 1343px #fff, 1316px 1649px #fff, 2077px 988px #fff, 733px 226px #fff,
    1804px 1735px #fff, 284px 1792px #fff, 360px 2312px #fff, 1022px 2246px #fff,
    1093px 2174px #fff, 2081px 1095px #fff, 1999px 842px #fff,
    1218px 1729px #fff, 449px 2170px #fff, 885px 690px #fff, 2466px 2360px #fff,
    81px 1890px #fff, 81px 2445px #fff, 569px 2493px #fff, 2013px 771px #fff,
    2026px 2046px #fff, 557px 416px #fff, 2054px 1237px #fff, 1078px 1220px #fff,
    2263px 250px #fff, 2526px 849px #fff, 1401px 2356px #fff, 51px 1157px #fff,
    1018px 584px #fff, 1400px 1627px #fff, 1990px 1896px #fff, 791px 115px #fff,
    246px 1518px #fff, 2049px 740px #fff, 2354px 1992px #fff, 930px 815px #fff,
    1997px 1415px #fff, 1570px 1250px #fff, 1075px 975px #fff, 70px 169px #fff,
    344px 1790px #fff, 2351px 2324px #fff, 1788px 1559px #fff,
    2431px 1658px #fff, 24px 963px #fff, 197px 408px #fff, 1624px 1049px #fff,
    1592px 296px #fff, 1775px 437px #fff, 149px 1946px #fff, 1455px 537px #fff,
    1856px 590px #fff, 1078px 1518px #fff, 1075px 1398px #fff, 49px 1480px #fff,
    1481px 249px #fff, 354px 1180px #fff, 1970px 265px #fff, 1013px 2286px #fff,
    1563px 99px #fff, 966px 2129px #fff, 1947px 259px #fff, 742px 2174px #fff,
    610px 363px #fff, 424px 1234px #fff, 383px 1380px #fff, 1775px 1641px #fff,
    998px 354px #fff, 2256px 1850px #fff, 484px 399px #fff, 222px 1717px #fff,
    1426px 2459px #fff, 2396px 1909px #fff, 2440px 225px #fff, 864px 965px #fff,
    624px 1836px #fff, 407px 2447px #fff, 789px 786px #fff, 1775px 2378px #fff,
    2028px 199px #fff, 1542px 50px #fff, 954px 881px #fff, 1043px 1358px #fff,
    6px 443px #fff, 679px 1118px #fff, 589px 914px #fff, 201px 2251px #fff,
    2406px 1039px #fff, 61px 1770px #fff, 1423px 2240px #fff, 2554px 1738px #fff,
    2518px 2213px #fff, 1248px 1016px #fff, 2462px 2114px #fff,
    711px 1248px #fff, 1660px 276px #fff, 2345px 86px #fff, 819px 1872px #fff,
    1938px 487px #fff, 1445px 1201px #fff, 1375px 985px #fff, 1039px 1686px #fff,
    344px 278px #fff, 532px 2541px #fff, 1029px 1476px #fff, 233px 1898px #fff,
    1090px 1304px #fff, 1466px 1124px #fff, 1059px 1168px #fff,
    1545px 950px #fff, 141px 2521px #fff, 867px 1165px #fff, 2362px 1629px #fff,
    1245px 1243px #fff, 602px 499px #fff, 1662px 231px #fff, 146px 349px #fff,
    1947px 232px #fff, 1175px 1285px #fff, 308px 2169px #fff, 2371px 1755px #fff,
    1771px 196px #fff, 2338px 2028px #fff, 867px 1319px #fff, 237px 1537px #fff,
    1176px 1291px #fff, 2538px 2526px #fff, 1484px 1559px #fff,
    900px 1399px #fff, 1429px 2538px #fff, 1785px 2140px #fff,
    2410px 2107px #fff, 2031px 950px #fff, 2005px 2425px #fff, 35px 860px #fff,
    940px 1482px #fff, 264px 1556px #fff, 2175px 548px #fff, 1166px 1321px #fff,
    778px 1962px #fff, 1612px 513px #fff, 605px 2372px #fff, 276px 1043px #fff,
    1012px 2544px #fff, 1095px 81px #fff, 2397px 2545px #fff, 1947px 2146px #fff,
    1536px 1896px #fff, 2283px 2404px #fff, 219px 2046px #fff, 1076px 930px #fff,
    938px 1285px #fff, 2460px 109px #fff, 1865px 130px #fff, 2479px 1051px #fff,
    1191px 843px #fff, 563px 2250px #fff, 827px 1567px #fff, 877px 2098px #fff,
    2274px 1353px #fff, 84px 663px #fff, 2122px 1061px #fff, 2033px 67px #fff,
    2356px 2486px #fff, 199px 692px #fff, 506px 298px #fff, 797px 548px #fff,
    1924px 313px #fff, 2097px 1353px #fff, 1850px 1725px #fff, 896px 2336px #fff,
    2390px 2024px #fff, 304px 497px #fff, 849px 809px #fff, 2448px 2206px #fff,
    1954px 2282px #fff, 1535px 1394px #fff, 1754px 1907px #fff,
    2206px 1216px #fff, 562px 1005px #fff, 2196px 2385px #fff, 1543px 206px #fff,
    486px 1369px #fff, 2338px 219px #fff, 1431px 765px #fff, 1433px 1858px #fff,
    1917px 642px #fff, 530px 186px #fff, 2078px 818px #fff, 2099px 1984px #fff,
    1826px 1003px #fff, 464px 2128px #fff, 1315px 570px #fff, 1184px 942px #fff,
    966px 415px #fff, 120px 1085px #fff, 1672px 2007px #fff, 1507px 205px #fff,
    861px 767px #fff, 794px 1542px #fff, 954px 261px #fff, 785px 2321px #fff,
    2558px 2304px #fff, 780px 1976px #fff, 402px 829px #fff, 2242px 1695px #fff,
    293px 2075px #fff, 54px 356px #fff, 1000px 1996px #fff, 1248px 371px #fff,
    308px 104px #fff, 738px 1912px #fff, 2232px 520px #fff, 2161px 2025px #fff,
    104px 2558px #fff, 1347px 2190px #fff, 1215px 1826px #fff, 302px 2504px #fff,
    1281px 1636px #fff, 2273px 1312px #fff, 1348px 1901px #fff,
    1276px 1844px #fff, 551px 235px #fff, 1249px 2187px #fff, 1830px 2048px #fff,
    94px 1080px #fff, 1866px 1054px #fff, 623px 2336px #fff, 793px 1946px #fff,
    1209px 588px #fff, 1077px 1831px #fff, 610px 935px #fff, 454px 2480px #fff,
    2204px 34px #fff, 909px 2415px #fff, 19px 17px #fff, 192px 914px #fff,
    1712px 1432px #fff, 169px 1041px #fff, 139px 419px #fff, 731px 763px #fff,
    1267px 1490px #fff, 2142px 122px #fff, 1341px 649px #fff, 119px 320px #fff;
}

.stars2 {
  z-index: 10;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: transparent;
  box-shadow: 827px 458px #fff, 1332px 1493px #fff, 2195px 2266px #fff,
    2011px 901px #fff, 1935px 1810px #fff, 1441px 14px #fff, 470px 1034px #fff,
    2418px 1405px #fff, 158px 1371px #fff, 1151px 2176px #fff, 2183px 201px #fff,
    2313px 686px #fff, 426px 1164px #fff, 798px 349px #fff, 2236px 740px #fff,
    851px 2096px #fff, 2216px 2176px #fff, 687px 2027px #fff, 439px 507px #fff,
    693px 318px #fff, 1320px 1305px #fff, 939px 1355px #fff, 1128px 1560px #fff,
    1824px 394px #fff, 2295px 2463px #fff, 800px 2415px #fff, 2182px 1981px #fff,
    141px 1520px #fff, 2454px 1186px #fff, 2536px 469px #fff, 2124px 469px #fff,
    684px 867px #fff, 2044px 670px #fff, 1303px 510px #fff, 133px 1538px #fff,
    215px 640px #fff, 2217px 1921px #fff, 237px 266px #fff, 175px 1585px #fff,
    412px 1054px #fff, 2216px 792px #fff, 2472px 1632px #fff, 425px 2024px #fff,
    1363px 1228px #fff, 3px 478px #fff, 2003px 1908px #fff, 2276px 2172px #fff,
    1725px 1012px #fff, 1554px 465px #fff, 1340px 1630px #fff,
    1063px 2272px #fff, 1168px 1542px #fff, 458px 407px #fff, 1794px 727px #fff,
    2069px 2344px #fff, 1754px 1977px #fff, 1643px 2373px #fff,
    1833px 1927px #fff, 878px 1578px #fff, 262px 2082px #fff, 943px 549px #fff,
    1380px 2154px #fff, 823px 630px #fff, 1608px 337px #fff, 2060px 547px #fff,
    1722px 2176px #fff, 832px 1839px #fff, 162px 2133px #fff, 847px 878px #fff,
    238px 2013px #fff, 1204px 2544px #fff, 634px 128px #fff, 553px 365px #fff,
    188px 1879px #fff, 284px 1057px #fff, 1163px 839px #fff, 1281px 1409px #fff,
    2447px 408px #fff, 2019px 408px #fff, 1718px 2044px #fff, 555px 1664px #fff,
    1405px 875px #fff, 155px 2411px #fff, 371px 2321px #fff, 2098px 2307px #fff,
    216px 2485px #fff, 1358px 1031px #fff, 170px 153px #fff, 644px 2291px #fff,
    1964px 2259px #fff, 2456px 1808px #fff, 2341px 1547px #fff,
    1243px 1318px #fff, 345px 144px #fff, 2087px 1193px #fff, 2185px 160px #fff,
    2280px 1099px #fff, 2107px 1474px #fff, 2325px 253px #fff, 165px 1436px #fff,
    1px 269px #fff, 1717px 2109px #fff, 667px 1661px #fff, 1298px 2106px #fff,
    2375px 591px #fff, 1891px 551px #fff, 2070px 850px #fff, 553px 1022px #fff,
    1927px 488px #fff, 1930px 397px #fff, 127px 854px #fff, 1505px 1663px #fff,
    2080px 279px #fff, 1501px 44px #fff, 1223px 2344px #fff, 2391px 445px #fff,
    1951px 936px #fff, 1391px 408px #fff, 692px 2442px #fff, 1642px 536px #fff,
    618px 1491px #fff, 1234px 337px #fff, 1115px 2242px #fff, 472px 285px #fff,
    943px 2392px #fff, 2472px 2092px #fff, 2239px 2272px #fff,
    1283px 1904px #fff, 1300px 1114px #fff, 1776px 2184px #fff,
    2268px 928px #fff, 979px 749px #fff, 2289px 620px #fff, 1799px 1245px #fff,
    2549px 2139px #fff, 841px 1582px #fff, 263px 1581px #fff, 167px 767px #fff,
    1006px 2082px #fff, 1281px 364px #fff, 366px 1528px #fff, 2364px 2126px #fff,
    2028px 547px #fff, 467px 255px #fff, 1080px 2320px #fff, 1317px 404px #fff,
    2115px 582px #fff, 1119px 2228px #fff, 51px 1756px #fff, 1407px 539px #fff,
    2028px 1714px #fff, 2433px 150px #fff, 1277px 73px #fff, 1663px 1347px #fff,
    1501px 519px #fff, 172px 2468px #fff, 907px 2146px #fff, 789px 2039px #fff,
    396px 2274px #fff, 2361px 712px #fff, 2303px 36px #fff, 391px 1960px #fff,
    1722px 498px #fff, 2465px 1916px #fff, 1460px 1875px #fff,
    1858px 1555px #fff, 2005px 1373px #fff, 1757px 2381px #fff,
    2265px 2447px #fff, 1082px 1779px #fff, 2006px 287px #fff, 1171px 430px #fff,
    1085px 121px #fff, 1177px 1624px #fff, 1822px 2116px #fff, 912px 199px #fff,
    522px 885px #fff, 2112px 62px #fff, 141px 1939px #fff, 2435px 2468px #fff,
    2490px 1349px #fff, 1595px 1802px #fff, 418px 1005px #fff,
    2000px 1886px #fff, 4px 1893px #fff, 323px 50px #fff, 1512px 1980px #fff,
    898px 1955px #fff, 848px 1185px #fff, 40px 1805px #fff, 873px 2547px #fff,
    2429px 1714px #fff, 223px 1549px #fff, 761px 2347px #fff, 2210px 2079px #fff,
    997px 2466px #fff, 780px 2383px #fff, 897px 662px #fff, 2175px 1011px #fff,
    1143px 806px #fff;
  animation: animStar 175s linear infinite;
}
.stars2:after {
  content: " ";
  top: -600px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  backgroud: transparent;
  box-shadow: 538px 704px #fff, 929px 1481px #fff, 1624px 672px #fff,
    2494px 1880px #fff, 1901px 345px #fff, 2298px 1246px #fff, 1204px 332px #fff,
    1085px 111px #fff, 572px 1013px #fff, 190px 622px #fff, 2291px 1609px #fff,
    879px 128px #fff, 237px 1555px #fff, 1606px 784px #fff, 341px 1251px #fff,
    2264px 258px #fff, 477px 785px #fff, 1824px 1876px #fff, 1707px 1533px #fff,
    1763px 323px #fff, 1238px 1345px #fff, 1714px 908px #fff, 1926px 388px #fff,
    1454px 1933px #fff, 1520px 1115px #fff, 1836px 681px #fff,
    1434px 2509px #fff, 1942px 977px #fff, 1123px 2092px #fff, 379px 947px #fff,
    1298px 12px #fff, 431px 2134px #fff, 1197px 1030px #fff, 2426px 807px #fff,
    617px 347px #fff, 2105px 1500px #fff, 1721px 380px #fff, 1320px 2407px #fff,
    668px 664px #fff, 2559px 1490px #fff, 2096px 1399px #fff, 942px 1660px #fff,
    597px 719px #fff, 869px 341px #fff, 1874px 29px #fff, 2090px 235px #fff,
    271px 1021px #fff, 2298px 1639px #fff, 305px 2433px #fff, 208px 534px #fff,
    462px 2463px #fff, 2298px 1785px #fff, 271px 229px #fff, 108px 2290px #fff,
    77px 2406px #fff, 280px 669px #fff, 848px 701px #fff, 1138px 153px #fff,
    1968px 2553px #fff, 1138px 2166px #fff, 1905px 252px #fff, 1341px 369px #fff,
    247px 1976px #fff, 2456px 2058px #fff, 1409px 1385px #fff,
    2495px 1490px #fff, 841px 1586px #fff, 1363px 772px #fff, 2339px 1338px #fff,
    524px 266px #fff, 2305px 1564px #fff, 14px 2407px #fff, 1729px 1119px #fff,
    1109px 1986px #fff, 2462px 1404px #fff, 1995px 1229px #fff,
    1651px 1867px #fff, 1963px 568px #fff, 1219px 1646px #fff, 37px 385px #fff,
    1345px 1705px #fff, 2193px 297px #fff, 2261px 2237px #fff,
    1564px 1716px #fff, 1120px 1740px #fff, 2379px 950px #fff,
    1196px 2239px #fff, 818px 1094px #fff, 1023px 1860px #fff, 296px 2265px #fff,
    2006px 546px #fff, 2437px 1607px #fff, 520px 1261px #fff, 1613px 1935px #fff,
    2064px 1845px #fff, 1971px 1437px #fff, 832px 2457px #fff, 2045px 600px #fff,
    1981px 279px #fff, 101px 390px #fff, 1871px 1312px #fff, 703px 1917px #fff,
    1656px 2390px #fff, 297px 430px #fff, 1626px 376px #fff, 1247px 2033px #fff,
    2250px 2068px #fff, 1768px 2095px #fff, 1081px 747px #fff,
    2412px 1089px #fff, 74px 1493px #fff, 1004px 324px #fff, 456px 703px #fff,
    1518px 767px #fff, 341px 1122px #fff, 581px 596px #fff, 2457px 1762px #fff,
    259px 1138px #fff, 392px 1990px #fff, 1410px 977px #fff, 420px 1387px #fff,
    410px 824px #fff, 1391px 1893px #fff, 2478px 2215px #fff, 2130px 1042px #fff,
    1235px 151px #fff, 2549px 864px #fff, 1213px 2537px #fff, 1874px 393px #fff,
    612px 549px #fff, 2040px 961px #fff, 1646px 1417px #fff, 2332px 1840px #fff,
    2509px 1543px #fff, 960px 1227px #fff, 1879px 1609px #fff,
    1867px 2560px #fff, 1279px 1338px #fff, 1324px 839px #fff,
    1891px 1077px #fff, 891px 2214px #fff, 1790px 2479px #fff, 1065px 661px #fff,
    295px 1202px #fff, 810px 732px #fff, 2082px 1582px #fff, 2539px 1350px #fff,
    1233px 2194px #fff, 1367px 1978px #fff, 1538px 533px #fff, 45px 794px #fff,
    2496px 2274px #fff, 2127px 773px #fff, 1230px 962px #fff, 2531px 49px #fff,
    1249px 1018px #fff, 1732px 1158px #fff, 221px 590px #fff, 862px 610px #fff,
    1466px 1293px #fff, 823px 2415px #fff, 553px 8px #fff, 82px 750px #fff,
    2114px 935px #fff, 478px 2486px #fff, 1376px 786px #fff, 2100px 1624px #fff,
    374px 542px #fff, 890px 796px #fff, 172px 2031px #fff, 1307px 727px #fff,
    330px 2527px #fff, 1255px 1433px #fff, 2532px 2029px #fff, 692px 222px #fff,
    2049px 1181px #fff, 261px 902px #fff, 2149px 989px #fff, 1184px 1455px #fff,
    1748px 325px #fff, 1696px 674px #fff, 1120px 1432px #fff, 1054px 1122px #fff,
    2403px 1685px #fff, 89px 855px #fff, 14px 14px #fff, 1523px 1182px #fff,
    2395px 2257px #fff, 2143px 2495px #fff, 759px 877px #fff, 457px 1085px #fff,
    1648px 1968px #fff, 665px 363px #fff, 2173px 2319px #fff, 2013px 2102px #fff,
    2115px 1198px #fff, 2186px 900px #fff, 1177px 2036px #fff,
    1308px 1109px #fff, 1200px 306px #fff;
}

.shooting-stars {
  z-index: 10;
  width: 5px;
  height: 85px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  background: linear-gradient(to top, rgba(255, 255, 255, 0), white);
  animation: animShootingStar 10s linear infinite;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2560px) translateX(-2560px);
  }
}
@keyframes animShootingStar {
  from {
    transform: translateY(0px) translateX(0px) rotate(-45deg);
    opacity: 1;
    height: 5px;
  }
  to {
    transform: translateY(-2560px) translateX(-2560px) rotate(-45deg);
    opacity: 1;
    height: 800px;
  }
}
</style>
