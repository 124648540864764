<template>
  <div class="flex items-center justify-center text-zinc-400 h-full">
    leaderboard page
  </div>
</template>

<script>
export default {
  name: "LeaderboardView",
};
</script>

<style scoped></style>
