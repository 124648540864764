<template>
  <div class="flex items-center justify-center text-zinc-400 h-full">
    resources page
  </div>
</template>

<script>
export default {
  name: "ResourcesView",
};
</script>

<style scoped></style>
