<template>
  <div class="flex items-center justify-center text-zinc-400 h-full">
    portfolio page
  </div>
</template>

<script>
export default {
  name: "PortfolioView",
};
</script>

<style scoped></style>
