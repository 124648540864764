<template>
  <div
    class="py-1 px-4 text-zinc-300 w-full bg-zinc-900 text-xs flex flex-row justify-between font-mono"
  >
    <div class="overflow-x-auto">
      <div class="flex flex-row items-center h-5 shrink-0">
        <div class="shrink-0">
          BTCUSD <span class="text-red-500">+1%</span> ·
        </div>
        <div class="shrink-0">
          BTCUSD <span class="text-emerald-500">+1%</span> ·
        </div>
        <div class="shrink-0">
          BTCUSD <span class="text-emerald-500">+1%</span> ·
        </div>
        <div class="shrink-0">
          BTCUSD <span class="text-emerald-500">+1%</span> ·
        </div>
        <div class="shrink-0">
          BTCUSD <span class="text-emerald-500">+1%</span> ·
        </div>
      </div>
    </div>
    <div>
      <UtcTime class="flex flex-row h-5 items-center w-28 justify-end" />
    </div>
  </div>
</template>

<script>
import UtcTime from "@/components/utility/UtcTime.vue";

export default {
  name: "TopNav",
  components: { UtcTime },
};
</script>

<style scoped></style>
