<template>
  <div class="flex flex-col h-screen">
    <header class="bg-zinc-800 shadow-sm">
      <div class="flex-row flex justify-between">
        <div
          class="group transition ease-in-out duration-300 delay-150 hover:text-zinc-100 w-72 text-zinc-400 cursor-pointer border-r px-4 py-3 border-zinc-700 flex flex-row justify-between items-center shrink-0"
          @click.prevent="onSwitch"
        >
          <div>BTC-USD-PERP</div>

          <div class="flex flex-row items-center">
            <div
              class="text-xs tracking-tight text-zinc-500 group-hover:text-zinc-400 mb-0.5 transition ease-in-out duration-300 delay-150"
            >
              (cmd + b)
            </div>
            <ArrowLeftIcon class="h-5 w-5 ml-2" />
          </div>

          <Hotkeys :shortcuts="['B']" @triggered="onSwitch" />
        </div>

        <div
          class="flex-grow flex items-center flex-row justify-between overflow-x-auto"
        >
          <div class="flex-grow flex flex-row">
            <div class="text-emerald-500 mx-4 flex items-center shrink-0">
              $<span class="font-mono">41,436</span>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">Index Price</div>
              <div class="text-zinc-300 text-xs">
                $<span class="font-mono">41,438</span>
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">Oracle Price</div>
              <div class="text-zinc-300 text-xs">
                $<span class="font-mono">41,438</span>
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">24h Change</div>
              <div class="text-red-500 text-xs">
                $<span class="font-mono">653.95 (1.54%)</span>
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">Open Interest</div>
              <div class="text-zinc-300 text-xs">
                <span class="font-mono">5,880.84</span>
                <span
                  class="bg-zinc-700 text-zinc-400 px-1 uppercase rounded ml-1"
                  >btc</span
                >
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">1h Funding</div>
              <div class="text-emerald-500 text-xs">
                <span class="font-mono">0.001864%</span>
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">24h Volume</div>
              <div class="text-zinc-300 text-xs">
                $<span class="font-mono">782,738,844</span>
              </div>
            </div>

            <div class="border-l border-zinc-700">&nbsp;</div>
            <div class="mx-4 -mt-0.5 shrink-0">
              <div class="text-zinc-500 text-xs">Next Funding</div>
              <div class="text-zinc-300 text-xs">
                <span class="font-mono">38:44</span>
              </div>
            </div>
            <div class="">&nbsp;</div>
          </div>
        </div>

        <div
          class="shrink-0 my-2 text-zinc-400 px-3 mr-1 border-l border-zinc-700 h-8 flex items-center transition ease-in-out duration-300 delay-150 hover:text-zinc-200 cursor-pointer"
        >
          <PlusIcon class="h-4 w-4" />
        </div>
      </div>
    </header>

    <main class="flex-grow">
      <div
        class="w-full h-full mx-auto flex bg-zinc-900 border-b border-t border-zinc-700 flex flex-col"
      >
        <div class="bg-zinc-800 px-4 py-4 border-b border-zinc-700">
          <div>
            <div class="text-xs text-zinc-300">
              Instrument
              <span class="text-zinc-500 ml-1">Select trading instrument</span>
            </div>
            <div>
              <div class="mt-1 relative rounded-md">
                <form @submit="onSwitch">
                  <input
                    ref="instrumentInput"
                    type="text"
                    class="block tracking-wider w-full text-zinc-300 font-mono px-4 py-4 pr-12 text-xl border-zinc-700 rounded bg-zinc-700 uppercase"
                    placeholder="BTC-USD-PERP"
                    aria-describedby="price-currency"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="flex-grow">
          <div class="grid grid-cols-3 text-zinc-300 h-full font-mono">
            <div>
              <div class="px-4 py-4 border-b border-zinc-700">
                <div class="uppercase text-xl font-bold tracking-wider">
                  btc
                </div>
                <div class="text-zinc-400">Bitcoin</div>
              </div>

              <div class="px-4 py-4 border-b border-zinc-700">
                <div class="uppercase text-xl font-bold tracking-wider">
                  eth
                </div>
                <div class="text-zinc-400">Ethereum</div>
              </div>

              <div class="px-4 py-4 border-b border-zinc-700">
                <div class="uppercase text-xl font-bold tracking-wider">
                  doge
                </div>
                <div class="text-zinc-400">DogeCoin</div>
              </div>
            </div>
            <div class="border-l border-r border-zinc-700">
              <div class="px-4 py-4 border-b border-zinc-700">
                <div class="uppercase text-xl font-bold tracking-wider">
                  usdt
                </div>
                <div class="text-zinc-400">US Dollar (tether)</div>
              </div>
            </div>
            <div>
              <div class="px-4 py-4 border-b border-zinc-700">
                <div class="uppercase text-xl font-bold tracking-wider">
                  perp
                </div>
                <div class="text-zinc-400">Perpetual future (no expiry)</div>
              </div>

              <div class="px-4 py-4 border-b border-zinc-700">
                <div class="uppercase text-xl font-bold tracking-wider">
                  220628
                </div>
                <div class="text-zinc-400">28th June 2022 future</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <footer class="bg-zinc-800">
      <div class="flex flex-row items-center justify-between h-8 pb-0.5">
        <div
          class="text-xs text-zinc-400 flex items-center w-72 md:border-r border-zinc-700 px-4 py-1"
        >
          <div class="w-1.5 h-1.5 rounded-full bg-green-500 mr-2"></div>
          <div
            class="text-xs text-zinc-400 flex items-center w-72 transition ease-in-out duration-300 delay-150 hover:text-zinc-200 cursor-pointer"
          >
            Operational <ExternalLinkIcon class="h-4 w-4 ml-1" />
          </div>
        </div>

        <div class="flex-grow md:border-r border-zinc-700">&nbsp;</div>

        <div
          class="text-xs text-zinc-400 flex justify-end md:justify-start items-center w-72 px-4 transition ease-in-out duration-300 delay-150 hover:text-zinc-200 cursor-pointer"
        >
          Join us on discord <ExternalLinkIcon class="h-4 w-4 ml-1" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  PlusIcon,
  ExternalLinkIcon,
  ArrowLeftIcon,
} from "@heroicons/vue/outline";
import Hotkeys from "vue-hotkeys-rt";

export default defineComponent({
  name: "FuturesView",
  components: {
    ExternalLinkIcon,
    PlusIcon,
    ArrowLeftIcon,
    Hotkeys,
  },

  data() {
    return {
      leverage: 1,
      time: "",
      timer: null,
    };
  },

  mounted() {
    this.$refs.instrumentInput.focus();
  },

  methods: {
    onSwitch() {
      this.$router.push({
        name: "trade",
      });
    },
  },
});
</script>
