import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import FuturesView from "@/views/trade/FuturesView.vue";
import ListingsView from "@/views/trade/ListingsView.vue";
import HomeView from "@/views/home/HomeView.vue";
import PortfolioView from "@/views/portfolio/PortfolioView.vue";
import LeaderboardView from "@/views/leaderboard/LeaderboardView.vue";
import EventsView from "@/views/events/EventsView.vue";
import ResourcesView from "@/views/resources/ResourcesView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/portfolio",
    name: "portfolio",
    component: PortfolioView,
  },
  {
    path: "/trade",
    name: "trade",
    component: FuturesView,
  },
  {
    path: "/trade/listings",
    name: "trade.listings",
    component: ListingsView,
  },
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: LeaderboardView,
  },
  {
    path: "/events",
    name: "events",
    component: EventsView,
  },
  {
    path: "/resources",
    name: "resources",
    component: ResourcesView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
