<template>
  <nav class="bg-zinc-800 border-b border-t border-zinc-700 rounded-t-2xl">
    <div class="mx-auto px-4">
      <div class="flex items-center justify-between h-10 my-1">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <img
              @click.prevent="navigate('home')"
              class="h-7 w-7 cursor-pointer"
              src="../../assets/logo.png"
              alt="Henux Markets"
            />
          </div>

          <div class="hidden md:block border-zinc-700 border-l ml-3 my-2">
            <div class="pl-4 -ml-3 flex items-baseline space-x-3 text-zinc-400">
              <span
                @click.prevent="navigate('portfolio')"
                class="cursor-pointer transition ease-in-out duration-300 delay-150 hover:text-zinc-200 px-3 py-1 rounded-md text-sm font-base"
                :class="{ 'text-zinc-200': active('portfolio') }"
                >Portfolio</span
              >

              <span
                @click.prevent="navigate('trade')"
                class="cursor-pointer transition ease-in-out duration-300 delay-150 hover:text-zinc-200 px-3 py-1 rounded-md text-sm font-base"
                :class="{ 'text-zinc-200': active('trade') }"
                >Trade</span
              >

              <span
                @click.prevent="navigate('leaderboard')"
                class="cursor-pointer transition ease-in-out duration-300 delay-150 hover:text-zinc-200 px-3 py-1 rounded-md text-sm font-base"
                :class="{ 'text-zinc-200': active('leaderboard') }"
                >Leaderboard</span
              >

              <span
                @click.prevent="navigate('events')"
                class="cursor-pointer transition ease-in-out duration-300 delay-150 hover:text-zinc-200 px-3 py-1 rounded-md text-sm font-base"
                :class="{ 'text-zinc-200': active('events') }"
                >Events</span
              >

              <span
                @click.prevent="navigate('resources')"
                class="cursor-pointer transition ease-in-out duration-300 delay-150 hover:text-zinc-200 px-3 py-1 rounded-md text-sm font-base"
                :class="{ 'text-zinc-200': active('resources') }"
                >Resources</span
              >
            </div>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="flex items-center">
            <!--
            <div class="flex items-center">
              <div
                  class="mr-1 flex flex-col items-center justify-start text-left"
              >
                <div class="text-xs font-medium text-zinc-400">
                  Equity
                  <div class="text-xs font-mono text-zinc-500">0.0000</div>
                </div>
              </div>

              <div
                  class="mx-3 flex flex-col items-center justify-start text-left"
              >
                <div class="text-xs font-medium text-zinc-400">
                  Available
                  <div class="text-xs font-mono text-zinc-500">0.0000</div>
                </div>
              </div>
            </div>
            -->

            <button
              type="button"
              class="transition ease-in-out duration-300 delay-150 hover:text-zinc-200 text-zinc-400 px-3 py-2 text-sm font-base"
            >
              <span class="sr-only">Documentation</span>
              <QuestionMarkCircleIcon class="h-5 w-5" />
            </button>

            <button
              type="button"
              class="transition border-l border-r border-zinc-700 my-1 ease-in-out duration-300 delay-150 hover:text-zinc-200 text-zinc-400 px-3 py-1 text-sm font-base"
            >
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-5 w-5" />
            </button>

            <div
              class="ml-3 text-sm text-zinc-400 flex flex-row items-center transition ease-in-out duration-300 delay-150 hover:text-zinc-200 cursor-pointer"
            >
              English
              <ChevronDownIcon class="h-4 w-4 ml-2" />
            </div>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <button
            type="button"
            class="bg-zinc-700 inline-flex items-center justify-center p-2 rounded-md text-zinc-400 hover:text-white hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-zinc-700 focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <!--
              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            -->
            <svg
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!--
              Heroicon name: outline/x

              Menu open: "block", Menu closed: "hidden"
            -->
            <svg
              class="hidden h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  BellIcon,
  QuestionMarkCircleIcon,
  ChevronDownIcon,
} from "@heroicons/vue/outline";

export default {
  name: "HeaderNav",
  components: { BellIcon, QuestionMarkCircleIcon, ChevronDownIcon },

  methods: {
    navigate(name) {
      // check for update here

      this.$router.push({ name });
    },

    active(name) {
      return this.$route.name && this.$route.name.includes(name);
    },
  },
};
</script>

<style scoped></style>
