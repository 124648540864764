<template>
  <div class="h-full">
    <div class="h-full flex flex-col justify-between" v-show="!loading">
      <div
        class="border-b border-zinc-700 text-zinc-500 grid grid-cols-3 py-2 h-tv-chart"
      >
        <div class="pl-4 flex items-center">
          <LockClosedIcon
            class="h-5 w-5 transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
          />
          <LockOpenIcon
            class="h-4 w-4 hidden transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
          />
        </div>

        <div class="flex flex-row justify-center items-center">
          <MinusIcon
            class="h-4 w-4 transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
          />
          <div class="w-4 text-center mx-4 text-sm select-none">5</div>
          <PlusIcon
            class="h-4 w-4 transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
          />
        </div>

        <div class="flex items-center justify-end pr-4">
          <ChevronDoubleUpIcon
            class="mr-2 w-4 h-4 transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
          />
          <ChevronDoubleDownIcon
            class="w-4 h-4 transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
          />
        </div>
      </div>

      <div
        class="bg-zinc-900 grid grid-cols-11 text-zinc-500 text-center text-xs py-1"
      >
        <div class="col-span-2">
          <span class="bg-zinc-800 text-zinc-500 px-2 pb-0.5 lowercase rounded"
            >mine</span
          >
        </div>
        <div class="col-span-2">
          <span class="bg-zinc-800 text-zinc-500 px-2 pb-0.5 lowercase rounded"
            >qty</span
          >
        </div>
        <div class="col-span-3">
          <span class="bg-zinc-800 text-zinc-500 px-2 pb-0.5 lowercase rounded"
            >price</span
          >
        </div>
        <div class="col-span-2">
          <span class="bg-zinc-800 text-zinc-500 px-2 pb-0.5 lowercase rounded"
            >qty</span
          >
        </div>
        <div class="col-span-2">
          <span class="bg-zinc-800 text-zinc-500 px-2 pb-0.5 lowercase rounded"
            >mine</span
          >
        </div>
      </div>

      <div class="flex-grow bg-zinc-900">
        <div
          ref="book"
          class="h-full font-mono text-zinc-400 text-sm text-center flex flex-col justify-between overflow-y-auto"
        >
          <div
            v-for="n in rows"
            :key="n"
            class="flex-grow grid grid-cols-11"
            :class="`row-` + n"
          >
            <div
              class="col-span-4 grid grid-cols-2 cursor-pointer border border-zinc-900 hover:border-emerald-500 rounded-sm"
            >
              <div
                class="flex items-center justify-center row-buy-own rounded-sm"
              ></div>
              <div
                class="bg-zinc-800 flex items-center justify-center row-buy-market rounded-sm"
              ></div>
            </div>

            <div
              v-show="n !== middle"
              class="col-span-3 border border-zinc-900 flex items-center justify-center text-xs font-base"
            >
              44,{{ 500 - n * tickSize }}
            </div>
            <div
              v-show="n === middle"
              class="col-span-3 bg-blue-900 border border-blue-600 flex items-center justify-center rounded-sm text-xs font-base"
            >
              44,{{ 500 - n * tickSize }}
            </div>

            <div
              class="col-span-4 grid grid-cols-2 cursor-pointer border border-zinc-900 hover:border-red-500 rounded-sm"
            >
              <div
                class="bg-zinc-800 flex items-center justify-center row-sell-market rounded-sm"
              ></div>
              <div
                class="flex items-center justify-center row-sell-own rounded-sm"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="loading" class="h-full flex flex-col justify-between">
      <div
        class="border-b border-zinc-700 text-zinc-500 grid grid-cols-3 py-2 h-tv-chart"
      ></div>

      <div
        class="flex-grow flex flex-row justify-center items-center bg-zinc-900"
      >
        <HorizontalLoader />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import { defineComponent } from "vue";
import {
  LockOpenIcon,
  LockClosedIcon,
  ChevronDoubleUpIcon,
  ChevronDoubleDownIcon,
  PlusIcon,
  MinusIcon,
} from "@heroicons/vue/outline";
import HorizontalLoader from "@/components/loader/HorizontalLoader";

export default defineComponent({
  name: "OrderBook",
  components: {
    HorizontalLoader,
    LockOpenIcon,
    LockClosedIcon,
    ChevronDoubleUpIcon,
    ChevronDoubleDownIcon,
    PlusIcon,
    MinusIcon,
  },

  data() {
    return {
      rows: 5,
      rowContent: [],

      middle: 2,

      observer: null,
      tickSize: 5,

      loading: true,
      init: false,

      bids: [],
      asks: [],
    };
  },

  mounted() {
    const vm = this;

    setTimeout(
      function (v) {
        v.onBook();
      },
      200,
      this
    );

    this.observer = new ResizeObserver(function (size) {
      vm.calculateRows(size[0].contentRect.height);

      if (!vm.init) {
        vm.init = true;

        vm.$nextTick(function () {
          vm.animateBook();
        });
      }
    });

    this.observer.observe(this.$refs.book);
  },

  unmounted() {
    if (this.observer !== null) {
      this.observer.disconnect();
    }
  },

  methods: {
    calculateRows(size) {
      this.rows = Math.floor(size / 30);
      this.middle = Math.floor(this.rows / 2);

      this.fillRowData();
    },

    fillRowData() {
      console.log("recalc");
    },

    animateBook() {
      const d = 0.4;
      const f = 0.05;

      let n = this.middle;
      for (let r = n; r >= 0; r--) {
        gsap.to(`.row-${r} div .row-buy-market`, {
          backgroundColor: "#047857",
          duration: 0,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${r} div .row-buy-own`, {
          backgroundColor: "#064e3b",
          duration: 0,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${r} div .row-buy-market`, {
          backgroundColor: "#27272a",
          duration: d,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${r} div .row-buy-own`, {
          backgroundColor: "#18181b",
          duration: d,
          delay: (n - this.middle) * d * f,
        });

        gsap.to(`.row-${r} div .row-sell-market`, {
          backgroundColor: "#991b1b",
          duration: 0,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${r} div .row-sell-own`, {
          backgroundColor: "#7f1d1d",
          duration: 0,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${r} div .row-sell-market`, {
          backgroundColor: "#27272a",
          duration: d,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${r} div .row-sell-own`, {
          backgroundColor: "#18181b",
          duration: d,
          delay: (n - this.middle) * d * f,
        });

        gsap.to(`.row-${n + 1} div .row-buy-market`, {
          backgroundColor: "#047857",
          duration: 0,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${n + 1} div .row-buy-own`, {
          backgroundColor: "#064e3b",
          duration: 0,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${n + 1} div .row-buy-market`, {
          backgroundColor: "#27272a",
          duration: d,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${n + 1} div .row-buy-own`, {
          backgroundColor: "#18181b",
          duration: d,
          delay: (n - this.middle) * d * f,
        });

        gsap.to(`.row-${n + 1} div .row-sell-market`, {
          backgroundColor: "#991b1b",
          duration: 0,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${n + 1} div .row-sell-own`, {
          backgroundColor: "#7f1d1d",
          duration: 0,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${n + 1} div .row-sell-market`, {
          backgroundColor: "#27272a",
          duration: d,
          delay: (n - this.middle) * d * f,
        });
        gsap.to(`.row-${n + 1} div .row-sell-own`, {
          backgroundColor: "#18181b",
          duration: d,
          delay: (n - this.middle) * d * f,
        });

        n++;
      }
    },

    onBook() {
      if (this.loading) {
        this.loading = false;
      }

      // mock data
      this.asks = [
        {
          price: 38954.5,
          size: 47200,
        },
        {
          price: 38955,
          size: 28500,
        },
        {
          price: 38955.5,
          size: 7500,
        },
        {
          price: 38956,
          size: 200,
        },
        {
          price: 38958.5,
          size: 5000,
        },
        {
          price: 38959.5,
          size: 100,
        },
        {
          price: 38961.5,
          size: 8500,
        },
        {
          price: 38962,
          size: 3000,
        },
        {
          price: 38962.5,
          size: 58600,
        },
        {
          price: 38963,
          size: 20000,
        },
      ];

      this.bids = [
        {
          price: 38954,
          size: 4900,
        },
        {
          price: 38947,
          size: 2100,
        },
        {
          price: 38946.5,
          size: 20000,
        },
        {
          price: 38946,
          size: 26400,
        },
        {
          price: 38941,
          size: 1600,
        },
        {
          price: 38940.5,
          size: 96800,
        },
        {
          price: 38940,
          size: 66900,
        },
        {
          price: 38939.5,
          size: 20900,
        },
        {
          price: 38938.5,
          size: 7500,
        },
        {
          price: 38938,
          size: 24100,
        },
      ];
    },
  },
});
</script>
