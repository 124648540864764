<template>
  <div class="h-full">
    <div class="h-full w-72 flex flex-col justify-between">
      <div class="h-tv-chart text-zinc-500 border-b border-zinc-700">
        <div
          class="flex flex-row items-center justify-end h-full grid grid-cols-2"
        >
          <div
            class="items-center flex justify-center px-1 py-1 text-xs text-zinc-300 font-base transition ease-in-out duration-300 delay-150 cursor-pointer"
          >
            Trades
          </div>
          <div
            class="items-center flex justify-center px-1 py-1 text-xs text-zinc-500 font-base transition ease-in-out duration-300 delay-150 hover:text-zinc-300 cursor-pointer"
          >
            Details
          </div>
        </div>
      </div>

      <div
        class="bg-zinc-900 grid grid-cols-12 text-zinc-500 text-center text-xs py-1 px-4"
      >
        <div>&nbsp;</div>
        <div class="col-span-3 text-right">
          <span class="bg-zinc-800 text-zinc-500 px-2 pb-0.5 lowercase rounded"
            >price</span
          >
        </div>
        <div class="col-span-3 text-right">
          <span class="bg-zinc-800 text-zinc-500 px-2 pb-0.5 lowercase rounded"
            >qty</span
          >
        </div>
        <div class="col-span-5 text-right">
          <span class="bg-zinc-800 text-zinc-500 px-2 pb-0.5 lowercase rounded"
            >time</span
          >
        </div>
      </div>

      <div
        v-show="loading"
        class="flex-grow bg-zinc-900 flex justify-center items-center"
      >
        <HorizontalLoader />
      </div>

      <div
        v-show="!trades.length && !loading"
        class="flex-grow bg-zinc-900 flex justify-center items-center"
      >
        <span class="text-sm text-zinc-600">No trades found</span>
      </div>

      <div
        v-show="trades.length && !loading"
        class="flex-grow flex-col bg-zinc-900 flex"
      >
        <div
          v-for="(t, i) in trades"
          :key="i"
          class="w-full px-4 font-mono text-sm"
        >
          <div
            class="grid grid-cols-12"
            :class="{ 'text-red-500': !t.isBuy, 'text-emerald-500': t.isBuy }"
          >
            <div>
              <span v-show="t.isBuy">B</span>
              <span v-show="!t.isBuy">S</span>
            </div>
            <div class="col-span-3 text-right">
              {{ t.price }}
            </div>
            <div class="col-span-3 text-right">
              {{ t.qty }}
            </div>
            <div class="col-span-5 text-right">
              {{ t.time }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HorizontalLoader from "@/components/loader/HorizontalLoader";
export default {
  name: "PublicTrades",
  components: { HorizontalLoader },
  data() {
    return {
      loading: true,

      trades: [
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: true,
        },
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: false,
        },
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: true,
        },
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: true,
        },
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: true,
        },
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: false,
        },
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: false,
        },
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: false,
        },
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: false,
        },
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: true,
        },
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: true,
        },
        {
          price: 43000,
          qty: 300,
          time: 0,
          isBuy: true,
        },
      ],
    };
  },

  mounted() {
    setTimeout(
      (vm) => {
        vm.loading = false;
      },
      200,
      this
    );
  },
};
</script>

<style scoped></style>
