<template>
  <span>{{ time }}</span>
</template>

<script>
export default {
  name: "UtcTime",

  data() {
    return {
      time: "",
      timer: null,
    };
  },

  mounted() {
    this.timer = setInterval(
      function (vm) {
        vm.updateTime();
      },
      1e3,
      this
    );

    this.updateTime();
  },

  methods: {
    updateTime() {
      const now = new Date();
      const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
      this.time = utc.toLocaleTimeString() + " UTC";
    },
  },

  unmounted() {
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style scoped></style>
