<template>
  <div
    v-on:click.prevent="click()"
    class="text-gray-300 transition duration-300 ease-in-out delay-150 rounded border-2 cursor-pointer hover:text-gray-100"
    :class="classes"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "BorderButton",

  props: {
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "border-zinc-500",
    },
    px: {
      type: String,
      default: "px-6",
    },
    py: {
      type: String,
      default: "py-1.5",
    },
    size: {
      type: String,
      default: "text-base",
    },
    align: {
      type: String,
      default: "text-center",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      buttonColor: "",
      paddingX: "",
      paddingY: "",
      textSize: "",
    };
  },

  methods: {
    click() {
      this.$emit("clicked");
    },
  },

  computed: {
    classes: function () {
      let c = `${this.color} ${this.px} ${this.py} ${this.size} ${this.align}`;

      if (this.active) {
        c = c + ` bg-gradient-to-t from-zinc-900`;
      } else {
        c = c + " text-zinc-500";
      }

      return c;
    },
  },
};
</script>

<style scoped></style>
