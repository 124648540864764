<template>
  <div class="tracking-wider subpixel-antialiased flex flex-col h-screen">
    <TopNav />

    <HeaderNav />

    <router-view />
  </div>
</template>

<script>
import TopNav from "@/components/layout/TopNav";
import HeaderNav from "@/components/layout/HeaderNav";

export default {
  components: { HeaderNav, TopNav },
};
</script>
