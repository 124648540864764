<template>
  <div
    v-on:click.prevent="click()"
    class="transition ease-in-out duration-300 delay-150 bg-gradient-to-b rounded border cursor-pointer hover:text-zinc-100"
    :class="classes"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "FullButton",

  props: {
    text: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "text-zinc-200",
    },
    backgroundColor: {
      type: String,
      default: "bg-zinc-800",
    },
    gradientColor: {
      type: String,
      default: "from-zinc-600",
    },
    borderColor: {
      type: String,
      default: "border-zinc-500",
    },
    px: {
      type: String,
      default: "px-6",
    },
    py: {
      type: String,
      default: "py-1.5",
    },
    size: {
      type: String,
      default: "text-base",
    },
    align: {
      type: String,
      default: "text-center",
    },
  },

  data() {
    return {
      buttonColor: "",
      paddingX: "",
      paddingY: "",
      textSize: "",
    };
  },

  methods: {
    click() {
      this.$emit("clicked");
    },
  },

  computed: {
    classes: function () {
      return `${this.borderColor} ${this.px} ${this.py} ${this.size} ${this.align} ${this.gradientColor} ${this.backgroundColor} ${this.textColor}`;
    },
  },
};
</script>

<style scoped></style>
